export const WORDS = [
  'adhum',
  'abane',
  'abene',
  'cokoh',
  'kusur',
  'katga',
  'memet',
  'murus',
  'mener',
  'nyuda',
  'oprak',
  'kubut',
  'bokor',
  'mrika',
  'bagna',
  'arnal',
  'nasag',
  'truka',
  'tatur',
  'medok',
  'ngaji',
  'sumuk',
  'nyoba',
  'jompo',
  'wuwul',
  'micik',
  'angeb',
  'along',
  'mesah',
  'pagut',
  'tentu',
  'gamol',
  'nahmi',
  'simik',
  'mayuh',
  'onten',
  'delem',
  'lolos',
  'kowal',
  'sabar',
  'metuk',
  'kasih',
  'pegat',
  'lumuh',
  'uneng',
  'lumut',
  'bejen',
  'musik',
  'maser',
  'kiyik',
  'crang',
  'mowal',
  'tapak',
  'marag',
  'bacin',
  'eming',
  'kutil',
  'tiron',
  'konce',
  'sonya',
  'reyal',
  'dawir',
  'brang',
  'welak',
  'gobab',
  'perti',
  'lanyo',
  'bacut',
  'lirna',
  'sorot',
  'juris',
  'sarag',
  'tanpa',
  'lepek',
  'gogoh',
  'bayar',
  'ujana',
  'benji',
  'wirya',
  'kokis',
  'encak',
  'bagus',
  'dhaun',
  'mloto',
  'sarap',
  'nuhun',
  'ejleg',
  'besus',
  'piadu',
  'muket',
  'gugut',
  'welon',
  'pecuk',
  'limas',
  'saren',
  'kamuk',
  'padho',
  'mogol',
  'beter',
  'irsya',
  'nisip',
  'mamar',
  'mayar',
  'paset',
  'tutuk',
  'dyota',
  'pulih',
  'klari',
  'slasa',
  'galik',
  'dadar',
  'gelar',
  'anana',
  'mogok',
  'ladat',
  'cawel',
  'lathi',
  'gigih',
  'ajang',
  'umbag',
  'cewer',
  'wluku',
  'entha',
  'sawud',
  'paceh',
  'ngilo',
  'esthi',
  'dilir',
  'segeh',
  'bejad',
  'bagja',
  'werta',
  'siyos',
  'milir',
  'sarah',
  'bekes',
  'keteg',
  'grami',
  'cebek',
  'bakmi',
  'prusa',
  'mobit',
  'jebad',
  'ancer',
  'sipil',
  'bleng',
  'gocek',
  'pogog',
  'bubak',
  'sraya',
  'sarta',
  'tanos',
  'nyade',
  'sarwi',
  'nutut',
  'sepet',
  'bawon',
  'inger',
  'nabuh',
  'puret',
  'kenot',
  'kiden',
  'muwus',
  'sorah',
  'dasan',
  'eyang',
  'eplup',
  'sasar',
  'sirig',
  'dhanu',
  'ripta',
  'cetho',
  'werdu',
  'sajen',
  'pecil',
  'paran',
  'johoh',
  'celus',
  'puncu',
  'magah',
  'tilak',
  'masuh',
  'udaya',
  'petel',
  'kusus',
  'royok',
  'kenul',
  'remik',
  'silad',
  'sareh',
  'jorna',
  'abing',
  'cepak',
  'edrel',
  'umbel',
  'sapit',
  'ontor',
  'cacab',
  'jaroh',
  'rajeg',
  'rawun',
  'ruhun',
  'logor',
  'sasak',
  'totoh',
  'jihma',
  'riyin',
  'widya',
  'selek',
  'celad',
  'bewel',
  'entek',
  'clana',
  'weroh',
  'risih',
  'powel',
  'totok',
  'bilih',
  'purek',
  'kerod',
  'gerji',
  'liwat',
  'tuwin',
  'ribig',
  'yuyut',
  'nirwa',
  'sepei',
  'selet',
  'juruh',
  'toreh',
  'enten',
  'solot',
  'wereg',
  'tebon',
  'blero',
  'jebeh',
  'langi',
  'bekos',
  'gacar',
  'diyan',
  'lulun',
  'dikir',
  'pipir',
  'nacah',
  'enjer',
  'begal',
  'arima',
  'gopel',
  'sujud',
  'kecik',
  'pageh',
  'sigid',
  'mewuh',
  'retna',
  'lecet',
  'cocoh',
  'lados',
  'gempi',
  'tapuk',
  'pilas',
  'gisik',
  'rugol',
  'paten',
  'peser',
  'raneh',
  'jagra',
  'ratih',
  'sulak',
  'rowan',
  'kikik',
  'bocel',
  'karna',
  'tlole',
  'nigan',
  'limit',
  'antah',
  'darma',
  'nyeka',
  'cocog',
  'jiwut',
  'hasti',
  'mobah',
  'nambi',
  'ancar',
  'kapit',
  'kekuk',
  'pucuk',
  'srati',
  'laksa',
  'burak',
  'nuwek',
  'babad',
  'oncat',
  'melok',
  'melor',
  'pupul',
  'kelud',
  'injet',
  'kilap',
  'intar',
  'sopir',
  'barek',
  'selop',
  'beger',
  'gejag',
  'marma',
  'komak',
  'tuhuk',
  'manda',
  'munte',
  'paril',
  'menek',
  'gojeg',
  'samek',
  'mrutu',
  'jenes',
  'berat',
  'kliki',
  'ungap',
  'jarwa',
  'enyet',
  'leteh',
  'lalim',
  'baten',
  'kecit',
  'datar',
  'antol',
  'molog',
  'jebag',
  'balak',
  'blawu',
  'gepah',
  'misah',
  'ksiti',
  'kawis',
  'ceger',
  'kapur',
  'pesus',
  'gewel',
  'menga',
  'jurug',
  'brewu',
  'kowan',
  'kojar',
  'sabat',
  'mawas',
  'garwa',
  'gemak',
  'batir',
  'jarag',
  'kulah',
  'lemen',
  'sarwa',
  'kalem',
  'anyem',
  'silar',
  'sempu',
  'ebruk',
  'cothe',
  'mogal',
  'tokuh',
  'hyang',
  'gaota',
  'jiwel',
  'kulur',
  'lajar',
  'pamuk',
  'upsir',
  'kaweh',
  'batos',
  'lumoh',
  'cegug',
  'mekik',
  'eyeng',
  'welan',
  'utawa',
  'pasik',
  'oncer',
  'kojat',
  'konto',
  'griwa',
  'kartu',
  'gaong',
  'maneh',
  'kadis',
  'wakes',
  'dwani',
  'ngayu',
  'mlole',
  'waris',
  'setip',
  'ojong',
  'padni',
  'yeyes',
  'saguh',
  'murka',
  'waseh',
  'jipun',
  'mreki',
  'mayor',
  'palem',
  'jaten',
  'cutak',
  'merdu',
  'kokot',
  'anyar',
  'tutas',
  'siyut',
  'gajih',
  'graha',
  'mojel',
  'guwan',
  'sebut',
  'kiyat',
  'buwek',
  'kadya',
  'kumbi',
  'cokot',
  'laron',
  'mileh',
  'wanti',
  'nispu',
  'jalmi',
  'sawon',
  'nitis',
  'redya',
  'sagah',
  'ampun',
  'milis',
  'kelat',
  'prata',
  'kibir',
  'kumel',
  'blung',
  'bakon',
  'buyan',
  'bisik',
  'mutih',
  'lamit',
  'imper',
  'angen',
  'gigil',
  'kelan',
  'resak',
  'riyeg',
  'gejus',
  'jamal',
  'geyot',
  'dalan',
  'darpa',
  'andum',
  'catho',
  'luwuk',
  'larab',
  'lepat',
  'nugar',
  'waita',
  'injuh',
  'patuh',
  'magut',
  'gupta',
  'pijar',
  'lurug',
  'modir',
  'konon',
  'endel',
  'wadon',
  'majas',
  'lukak',
  'brutu',
  'nglus',
  'ketep',
  'sinom',
  'ciklu',
  'blaka',
  'jatah',
  'dumuh',
  'wakta',
  'dhewe',
  'kekah',
  'krese',
  'mitra',
  'nyele',
  'patos',
  'karon',
  'inget',
  'bahem',
  'cerok',
  'singi',
  'odhak',
  'dalal',
  'graok',
  'patil',
  'tenen',
  'jiwir',
  'napel',
  'koyan',
  'lorod',
  'nekak',
  'rekes',
  'ingas',
  'niken',
  'mamut',
  'nyari',
  'sumek',
  'rakus',
  'garpu',
  'acala',
  'rabet',
  'retep',
  'salab',
  'tekan',
  'masoh',
  'rompi',
  'kupon',
  'susoh',
  'hasta',
  'ramon',
  'ajaib',
  'ngasi',
  'renek',
  'mapat',
  'sicok',
  'butun',
  'mipik',
  'srawa',
  'jambu',
  'pijer',
  'oncen',
  'remis',
  'puluh',
  'pihak',
  'parak',
  'buwel',
  'pesan',
  'pakal',
  'sadhu',
  'bogor',
  'petis',
  'palih',
  'surga',
  'canes',
  'sindu',
  'lorek',
  'tusuk',
  'kacip',
  'deleh',
  'memak',
  'edhun',
  'munuh',
  'biyah',
  'kojah',
  'banda',
  'dobol',
  'gegeg',
  'mehen',
  'bakat',
  'rejah',
  'bawur',
  'kemar',
  'rajun',
  'karus',
  'muyab',
  'salib',
  'cakot',
  'kerak',
  'rawon',
  'bobot',
  'klasi',
  'repak',
  'lidhi',
  'jabud',
  'jubak',
  'tamtu',
  'kowak',
  'dipta',
  'nrusi',
  'arbei',
  'kejut',
  'bebes',
  'toyah',
  'anala',
  'wewah',
  'besmi',
  'karuk',
  'subur',
  'bagor',
  'cecok',
  'sukur',
  'netya',
  'ngaso',
  'dulur',
  'nilas',
  'pretu',
  'gapit',
  'ireng',
  'cakal',
  'jemah',
  'duduk',
  'macas',
  'daden',
  'kolik',
  'pelah',
  'grama',
  'pedon',
  'nuras',
  'sruti',
  'wilah',
  'mares',
  'recon',
  'sunat',
  'cemot',
  'korup',
  'macet',
  'labet',
  'inten',
  'walun',
  'lamat',
  'uthun',
  'koloh',
  'belik',
  'pulir',
  'setin',
  'lumba',
  'kuluk',
  'pacuk',
  'bagen',
  'bedho',
  'pulas',
  'rolak',
  'kolam',
  'kikir',
  'luyup',
  'engek',
  'prusi',
  'lubar',
  'ebeng',
  'dlika',
  'lihat',
  'kepel',
  'pamit',
  'dhyam',
  'dalah',
  'delah',
  'hamba',
  'gejos',
  'wiyos',
  'incip',
  'cupet',
  'cemek',
  'sirat',
  'rembe',
  'tanak',
  'kerta',
  'acung',
  'bebed',
  'byoma',
  'serdu',
  'prabu',
  'sluku',
  'laweh',
  'mresa',
  'myang',
  'labur',
  'mamet',
  'wijen',
  'malek',
  'encep',
  'tayeh',
  'wenga',
  'atawa',
  'besur',
  'jegul',
  'marud',
  'unyel',
  'ngunu',
  'jepot',
  'sidep',
  'murni',
  'kosod',
  'kubra',
  'tajen',
  'ramud',
  'lahru',
  'idhep',
  'karet',
  'pisuh',
  'injen',
  'kutub',
  'blalo',
  'licin',
  'lajon',
  'mirib',
  'bibit',
  'jolor',
  'belih',
  'mlete',
  'regek',
  'ngawe',
  'panci',
  'negil',
  'kerup',
  'ngapa',
  'wesya',
  'ciwik',
  'jupuk',
  'nujus',
  'marit',
  'seler',
  'sarak',
  'pelak',
  'maler',
  'malya',
  'welun',
  'atita',
  'salep',
  'telar',
  'jujut',
  'kroya',
  'sumar',
  'sepre',
  'trena',
  'ngilu',
  'dadak',
  'bekti',
  'godag',
  'kerud',
  'citra',
  'slaga',
  'lorah',
  'gumuk',
  'nyene',
  'modin',
  'buruh',
  'erang',
  'perot',
  'byapi',
  'capar',
  'belok',
  'kesod',
  'enjet',
  'pawon',
  'buruk',
  'krami',
  'buket',
  'welur',
  'akwan',
  'laket',
  'gecak',
  'engah',
  'dlame',
  'uliya',
  'cikne',
  'samur',
  'seren',
  'cules',
  'kothi',
  'kereh',
  'gejig',
  'lesmi',
  'guwat',
  'rawik',
  'gowah',
  'pikul',
  'cuken',
  'cawak',
  'sirah',
  'begod',
  'leram',
  'rawod',
  'tokid',
  'jalar',
  'popol',
  'sikep',
  'konok',
  'mikat',
  'bambu',
  'ambus',
  'gayuk',
  'nucuk',
  'tangi',
  'boleh',
  'teler',
  'cegek',
  'seten',
  'daksa',
  'tilik',
  'empog',
  'embet',
  'losok',
  'meyan',
  'badal',
  'nosor',
  'cegok',
  'ripah',
  'sujen',
  'jikuk',
  'lajer',
  'liyan',
  'rurub',
  'abyor',
  'tular',
  'miyup',
  'perlu',
  'seseh',
  'tesek',
  'empeh',
  'lugut',
  'lepya',
  'keral',
  'kalar',
  'bujur',
  'sawit',
  'lerak',
  'ketat',
  'menol',
  'keleh',
  'miwah',
  'ambik',
  'absah',
  'polih',
  'minta',
  'diman',
  'werti',
  'kubon',
  'sarpa',
  'parwa',
  'lelet',
  'ngeki',
  'warna',
  'kekal',
  'cirak',
  'gurih',
  'kojud',
  'gincu',
  'tusih',
  'rawud',
  'loloh',
  'iyasa',
  'punar',
  'gecil',
  'tugur',
  'ringi',
  'nakar',
  'kemit',
  'kayat',
  'balur',
  'gitik',
  'melep',
  'clewo',
  'liswa',
  'latoh',
  'guris',
  'setem',
  'mereg',
  'ngure',
  'onjot',
  'bluwi',
  'parji',
  'mrono',
  'pasah',
  'gesuh',
  'onjet',
  'karsa',
  'wisma',
  'cripu',
  'pelit',
  'wregu',
  'bebak',
  'sirep',
  'aclum',
  'garda',
  'basir',
  'oneng',
  'rumat',
  'lokro',
  'kulit',
  'wedok',
  'mathu',
  'keceh',
  'cocor',
  'lowar',
  'imbet',
  'watuk',
  'kocak',
  'ngudi',
  'apang',
  'jahat',
  'bejer',
  'dipak',
  'bebah',
  'perdi',
  'palir',
  'rewel',
  'sebet',
  'palak',
  'beneh',
  'nagna',
  'sebab',
  'brama',
  'ngijo',
  'delan',
  'saiki',
  'lunyu',
  'miyek',
  'nonol',
  'anjil',
  'gecut',
  'lijig',
  'embok',
  'jubur',
  'kocor',
  'kolek',
  'keran',
  'wacan',
  'braja',
  'metal',
  'wacak',
  'bawah',
  'kalen',
  'priwe',
  'kemon',
  'jeger',
  'ribed',
  'keluk',
  'regep',
  'meled',
  'adang',
  'saron',
  'kanti',
  'amral',
  'radan',
  'likuk',
  'kalis',
  'pener',
  'bajug',
  'juwit',
  'umbuk',
  'imbon',
  'kalal',
  'liyun',
  'tarka',
  'kurda',
  'gemuk',
  'edang',
  'mupug',
  'laywa',
  'lojeg',
  'paham',
  'gilok',
  'setal',
  'jajap',
  'gapet',
  'petok',
  'cacal',
  'jawar',
  'letho',
  'surya',
  'jaluk',
  'semen',
  'nemah',
  'puket',
  'astra',
  'sweni',
  'ingip',
  'tiwar',
  'nikah',
  'swara',
  'meyok',
  'jiyot',
  'jalal',
  'engon',
  'pisah',
  'jalak',
  'leweg',
  'ciyak',
  'meren',
  'lagya',
  'pumpa',
  'gelam',
  'telek',
  'copot',
  'kiyen',
  'laren',
  'laken',
  'kader',
  'tumut',
  'ubyar',
  'dangu',
  'nyami',
  'bidho',
  'lintu',
  'nadar',
  'jerih',
  'gupya',
  'iklas',
  'tekuk',
  'neluh',
  'rotan',
  'jipuk',
  'bunci',
  'somah',
  'nembe',
  'matak',
  'cucuk',
  'jipro',
  'lamon',
  'maisi',
  'usana',
  'beken',
  'sungu',
  'padmi',
  'tambe',
  'sebul',
  'barut',
  'duduh',
  'dhara',
  'slira',
  'idhun',
  'esong',
  'dhito',
  'rakit',
  'ukung',
  'etung',
  'letih',
  'rante',
  'nenek',
  'petan',
  'pipil',
  'letuh',
  'wetan',
  'ngemu',
  'jeram',
  'nebar',
  'jasad',
  'sadur',
  'mesum',
  'patut',
  'udaka',
  'andak',
  'empus',
  'tutul',
  'gejeg',
  'popog',
  'mreta',
  'ameng',
  'lahar',
  'panca',
  'glega',
  'sukak',
  'deres',
  'rolas',
  'garad',
  'neles',
  'mlalu',
  'cegoh',
  'macel',
  'gemok',
  'crita',
  'tarik',
  'makah',
  'nukel',
  'untab',
  'amreh',
  'ganas',
  'ising',
  'nonob',
  'gamam',
  'ragas',
  'boleg',
  'cilon',
  'kewal',
  'lereh',
  'boreh',
  'kibik',
  'ketul',
  'sinau',
  'timah',
  'mutus',
  'mokla',
  'dudur',
  'noleh',
  'tepet',
  'gadha',
  'cawen',
  'karut',
  'seyos',
  'nggih',
  'katam',
  'jabah',
  'petak',
  'lohat',
  'narya',
  'bajag',
  'adhek',
  'drohi',
  'burat',
  'tugae',
  'imbal',
  'serog',
  'cihna',
  'geyuk',
  'padhe',
  'pasal',
  'sisah',
  'kisas',
  'bijil',
  'gigir',
  'empak',
  'asura',
  'keris',
  'gluga',
  'caeng',
  'kayah',
  'tekon',
  'dhami',
  'kaden',
  'sakti',
  'ijoan',
  'mwang',
  'teles',
  'tonto',
  'bekok',
  'jilma',
  'smara',
  'embuk',
  'dwija',
  'musus',
  'panil',
  'metis',
  'bakmu',
  'rapoh',
  'tumpu',
  'kikis',
  'harsa',
  'maksi',
  'mekan',
  'lumah',
  'peken',
  'merah',
  'mligi',
  'kenya',
  'matal',
  'anjel',
  'angde',
  'weteh',
  'enggo',
  'enjoh',
  'reyod',
  'rabas',
  'nenes',
  'kadug',
  'lasem',
  'cubak',
  'pluta',
  'cemak',
  'oyong',
  'riyuh',
  'berak',
  'yukti',
  'penak',
  'injil',
  'hulun',
  'kroso',
  'bered',
  'magag',
  'kakag',
  'jemek',
  'bapem',
  'bekel',
  'lileh',
  'kagem',
  'nyeos',
  'trate',
  'kobok',
  'telon',
  'pirid',
  'rujuk',
  'bogol',
  'dedel',
  'duwek',
  'rokok',
  'kedal',
  'ngira',
  'onggo',
  'wedel',
  'lagur',
  'remeh',
  'sigeg',
  'utama',
  'behel',
  'geroh',
  'kumpa',
  'ruseg',
  'lewih',
  'ngait',
  'jasem',
  'sehat',
  'luhur',
  'rusuh',
  'sewek',
  'lagis',
  'lebus',
  'empet',
  'karoh',
  'jagat',
  'molih',
  'kebek',
  'ambah',
  'lamen',
  'kupur',
  'lajim',
  'wiwal',
  'engge',
  'raket',
  'trigu',
  'pajar',
  'gesik',
  'tulis',
  'delok',
  'dekek',
  'kafir',
  'garuh',
  'gesah',
  'marep',
  'napsu',
  'emben',
  'wlira',
  'lisya',
  'riyel',
  'kowah',
  'kraka',
  'nayuh',
  'rekah',
  'dhung',
  'kalek',
  'prani',
  'larak',
  'loyor',
  'tluki',
  'ngiri',
  'tilar',
  'tatan',
  'lirik',
  'madeg',
  'bluju',
  'jarak',
  'maeng',
  'guwel',
  'gling',
  'seret',
  'upaos',
  'gudhe',
  'kumis',
  'mokal',
  'nilar',
  'sirsa',
  'unyar',
  'pugal',
  'cuwik',
  'senik',
  'untap',
  'kuksi',
  'tutut',
  'tegeg',
  'peret',
  'kelek',
  'lawas',
  'embun',
  'setir',
  'semog',
  'mayat',
  'motok',
  'braok',
  'gugus',
  'luwus',
  'sebel',
  'lalya',
  'ancas',
  'likas',
  'mahen',
  'serik',
  'padal',
  'untir',
  'yaksa',
  'gelur',
  'bunek',
  'titis',
  'entul',
  'kesuk',
  'mapah',
  'sirik',
  'mumet',
  'keloh',
  'otong',
  'ridhu',
  'majuh',
  'sowan',
  'tepen',
  'surti',
  'abrag',
  'sawal',
  'warok',
  'wutah',
  'tanah',
  'menut',
  'cepuk',
  'ladak',
  'laris',
  'panah',
  'setop',
  'edhok',
  'nusup',
  'nilem',
  'cawad',
  'seper',
  'giyao',
  'kolir',
  'mluku',
  'ancur',
  'bujul',
  'rusit',
  'antep',
  'bubat',
  'seton',
  'enjah',
  'seneb',
  'werut',
  'lehya',
  'ganal',
  'mubal',
  'bawal',
  'cokak',
  'totog',
  'jodhi',
  'mayus',
  'kanin',
  'pacal',
  'encik',
  'mantu',
  'bosin',
  'garap',
  'tuton',
  'kopan',
  'kuled',
  'jarna',
  'kikib',
  'gacos',
  'bowek',
  'narah',
  'amril',
  'sandi',
  'pikut',
  'blacu',
  'kertu',
  'coban',
  'kedhi',
  'salad',
  'kewok',
  'korep',
  'liyur',
  'halal',
  'peket',
  'rauru',
  'mrica',
  'nusut',
  'kliwa',
  'matek',
  'kuwik',
  'mugen',
  'pulur',
  'cumbu',
  'siwur',
  'kirab',
  'emreh',
  'nutul',
  'ening',
  'tutug',
  'erong',
  'kesel',
  'rebed',
  'sinyo',
  'embek',
  'parag',
  'tipis',
  'nekel',
  'walur',
  'sisik',
  'liris',
  'mosel',
  'salam',
  'udani',
  'kaula',
  'ngopi',
  'ingah',
  'bendi',
  'wayuh',
  'cetha',
  'jikik',
  'najug',
  'rempu',
  'cucul',
  'tulen',
  'anung',
  'taroh',
  'gicel',
  'nucup',
  'sriya',
  'labda',
  'napuk',
  'jemur',
  'wyasa',
  'sweta',
  'malem',
  'mribu',
  'meres',
  'kesot',
  'kenen',
  'ayang',
  'saptu',
  'korog',
  'teres',
  'paruk',
  'teluk',
  'gigik',
  'copil',
  'wales',
  'tinja',
  'mikir',
  'truni',
  'cenuk',
  'cabar',
  'tukel',
  'tekem',
  'masik',
  'kukud',
  'werda',
  'mekuh',
  'komuk',
  'puran',
  'gamel',
  'miyak',
  'kolom',
  'garba',
  'kesed',
  'wajir',
  'laran',
  'sahid',
  'druni',
  'geber',
  'rinci',
  'syara',
  'parek',
  'kirih',
  'boler',
  'empal',
  'riwuk',
  'nyero',
  'mlolo',
  'glelo',
  'teger',
  'klebu',
  'embeg',
  'nyaru',
  'ruhur',
  'suhun',
  'mampu',
  'resah',
  'amber',
  'gigol',
  'jinak',
  'wwang',
  'kelop',
  'endha',
  'nyana',
  'royal',
  'kolug',
  'sisir',
  'tatya',
  'kalih',
  'godhi',
  'incak',
  'tawil',
  'sligi',
  'pawat',
  'clolo',
  'dilep',
  'kenes',
  'besem',
  'latra',
  'titih',
  'sajak',
  'sodor',
  'adhik',
  'kapah',
  'medal',
  'edhep',
  'baris',
  'wuwuh',
  'pacok',
  'lukha',
  'lerob',
  'gathi',
  'nyamu',
  'salar',
  'lapat',
  'anyir',
  'jumut',
  'andam',
  'junun',
  'jamak',
  'pragi',
  'ethes',
  'kamli',
  'lotho',
  'kuwal',
  'entok',
  'nocol',
  'minum',
  'tekik',
  'sraba',
  'mukti',
  'burek',
  'gadri',
  'gugah',
  'kepep',
  'kipit',
  'gupuh',
  'tipes',
  'takal',
  'jukuk',
  'majar',
  'pasih',
  'nampu',
  'karen',
  'picik',
  'mapar',
  'tekpo',
  'sabtu',
  'weyen',
  'rumab',
  'dokok',
  'kopos',
  'korek',
  'pasek',
  'botel',
  'kodal',
  'cacag',
  'wanen',
  'gadan',
  'adhag',
  'ninja',
  'kerpu',
  'habib',
  'kutuk',
  'empot',
  'sewer',
  'jugur',
  'todhi',
  'repeh',
  'reksi',
  'ciwel',
  'liyar',
  'pucak',
  'eteng',
  'cokol',
  'indel',
  'gawan',
  'katog',
  'lisah',
  'bikal',
  'bubuh',
  'mocot',
  'kulup',
  'gonah',
  'bisae',
  'lukan',
  'leken',
  'lined',
  'mlaya',
  'cobek',
  'kucah',
  'rukun',
  'cucak',
  'adhel',
  'nyawa',
  'ekrak',
  'pagah',
  'benem',
  'wyata',
  'debal',
  'encek',
  'mones',
  'wrena',
  'gowok',
  'getul',
  'jugar',
  'mlulu',
  'puyuh',
  'kijat',
  'dolek',
  'bulud',
  'artos',
  'lobar',
  'terus',
  'diyer',
  'gucel',
  'lilit',
  'nudur',
  'semat',
  'kalah',
  'nisih',
  'lulut',
  'jelok',
  'kutis',
  'reged',
  'nucuh',
  'mipil',
  'reges',
  'arwah',
  'warti',
  'pulet',
  'wewer',
  'rejeg',
  'sliri',
  'mumur',
  'ethor',
  'gejug',
  'kemba',
  'penyu',
  'kegut',
  'sedik',
  'masir',
  'murga',
  'liter',
  'rigen',
  'sedut',
  'aspal',
  'regas',
  'aruna',
  'guwek',
  'gogok',
  'tempo',
  'lilab',
  'bodho',
  'goroh',
  'ejret',
  'tumon',
  'uleng',
  'ripak',
  'eleng',
  'mawur',
  'bayen',
  'bobor',
  'welek',
  'ampeg',
  'lunga',
  'racun',
  'momor',
  'ksira',
  'rebes',
  'padik',
  'meksi',
  'nyina',
  'perek',
  'deder',
  'zaman',
  'reres',
  'weden',
  'tarpa',
  'uthut',
  'bethu',
  'endra',
  'tebel',
  'kadga',
  'payun',
  'amrih',
  'kesit',
  'hurup',
  'klika',
  'posol',
  'iklim',
  'jaler',
  'keduk',
  'omyeh',
  'cakma',
  'reseh',
  'satru',
  'endon',
  'rewah',
  'cecep',
  'cegah',
  'ksaya',
  'pesat',
  'wadhe',
  'uthek',
  'bucek',
  'legeh',
  'kewuh',
  'pulus',
  'lalun',
  'mugut',
  'encup',
  'sopal',
  'cuban',
  'yatin',
  'modar',
  'lowak',
  'macan',
  'nggen',
  'wesel',
  'girah',
  'tumbu',
  'melai',
  'agrin',
  'ompol',
  'ngutu',
  'sikak',
  'upruk',
  'tarub',
  'gojog',
  'barat',
  'bunen',
  'sakat',
  'tampo',
  'sosor',
  'wurus',
  'mewes',
  'rerem',
  'ceyok',
  'angot',
  'kasub',
  'amara',
  'ratna',
  'watun',
  'kecut',
  'raham',
  'jemun',
  'jamah',
  'ampar',
  'reksa',
  'lebet',
  'pokil',
  'jempo',
  'dupeh',
  'setan',
  'sebit',
  'latin',
  'nerka',
  'yakin',
  'geter',
  'rolah',
  'koros',
  'wukon',
  'sidik',
  'tawur',
  'brito',
  'bares',
  'karya',
  'cimit',
  'khoja',
  'apkir',
  'mupus',
  'dimar',
  'bayan',
  'udhal',
  'dwipa',
  'sojah',
  'serok',
  'glolo',
  'jolen',
  'tabon',
  'asung',
  'kurug',
  'deler',
  'gagat',
  'siwah',
  'larag',
  'tukup',
  'wipra',
  'goreh',
  'krura',
  'majan',
  'padma',
  'kreti',
  'angel',
  'tekap',
  'sapta',
  'menak',
  'cakah',
  'tisna',
  'uthil',
  'kalet',
  'dubug',
  'sisya',
  'ketos',
  'peper',
  'tukon',
  'incih',
  'plelu',
  'dalil',
  'legok',
  'amrik',
  'kerig',
  'muhun',
  'buwak',
  'umpak',
  'sugat',
  'dyana',
  'wastu',
  'mlira',
  'geteh',
  'surud',
  'dirga',
  'kohir',
  'sambu',
  'kayut',
  'gelis',
  'gasah',
  'retah',
  'urang',
  'wohan',
  'wakca',
  'surba',
  'cohok',
  'gadho',
  'uning',
  'patah',
  'sipat',
  'silat',
  'tempe',
  'ngari',
  'mesem',
  'udadi',
  'setum',
  'tanja',
  'medel',
  'murda',
  'sabak',
  'lawad',
  'inyek',
  'tilas',
  'resun',
  'remes',
  'peras',
  'mejen',
  'lamis',
  'waled',
  'ketel',
  'ngame',
  'degan',
  'tyaga',
  'embak',
  'wasuh',
  'pilus',
  'wekas',
  'basmi',
  'jubah',
  'nyeru',
  'dhaya',
  'miyum',
  'jelak',
  'jirat',
  'papag',
  'sambi',
  'blila',
  'dhoso',
  'reden',
  'leret',
  'larih',
  'moreg',
  'gecul',
  'togna',
  'sangu',
  'gobag',
  'cewol',
  'lawun',
  'traos',
  'jipik',
  'karma',
  'dodot',
  'kalam',
  'notes',
  'mawar',
  'sorok',
  'geluk',
  'pekek',
  'buwoh',
  'gesor',
  'wineh',
  'celeb',
  'otheh',
  'kwaga',
  'kedan',
  'beruk',
  'setat',
  'hukum',
  'grana',
  'purus',
  'incat',
  'macul',
  'amung',
  'sewet',
  'bence',
  'apyun',
  'gojag',
  'sudra',
  'rupit',
  'cekoh',
  'jajil',
  'celek',
  'giyar',
  'priki',
  'lasak',
  'dwaja',
  'motha',
  'towuk',
  'lulur',
  'kiris',
  'maras',
  'bukti',
  'buwal',
  'helep',
  'andah',
  'tlika',
  'baran',
  'matur',
  'jupot',
  'lokha',
  'beret',
  'nereh',
  'caton',
  'peloh',
  'sruni',
  'oklek',
  'dhuku',
  'teges',
  'ejrah',
  'pongo',
  'putus',
  'bocah',
  'muser',
  'moeli',
  'penet',
  'rewes',
  'mohal',
  'angur',
  'mrosa',
  'nakir',
  'gagal',
  'jiyut',
  'jajar',
  'papak',
  'labeh',
  'manci',
  'janak',
  'resmi',
  'antre',
  'kwolu',
  'tayub',
  'warni',
  'pruwa',
  'masud',
  'sayid',
  'edhuk',
  'wawal',
  'titir',
  'sekat',
  'eting',
  'nenep',
  'gogot',
  'mluwa',
  'gubel',
  'mencu',
  'empuk',
  'celom',
  'rikne',
  'besat',
  'wraha',
  'meksa',
  'kalbu',
  'gacuk',
  'lagna',
  'bento',
  'notor',
  'pemut',
  'jawuh',
  'riwut',
  'bengi',
  'dhing',
  'sisip',
  'wlija',
  'lotis',
  'pecel',
  'gepok',
  'emboh',
  'kuwel',
  'unang',
  'tewek',
  'nguku',
  'lalay',
  'rucat',
  'kelin',
  'cabud',
  'kolah',
  'kurap',
  'kepes',
  'gecok',
  'gereg',
  'nemen',
  'riweg',
  'guthi',
  'wajar',
  'melit',
  'mibur',
  'lingi',
  'ejaan',
  'abrit',
  'ugeng',
  'seket',
  'kanji',
  'tanya',
  'donya',
  'udhis',
  'rikma',
  'buluk',
  'sewot',
  'besek',
  'ental',
  'lamba',
  'drema',
  'gayur',
  'banat',
  'lacur',
  'bekuk',
  'lugas',
  'gabag',
  'colok',
  'bewah',
  'meler',
  'bocok',
  'piker',
  'giyak',
  'rupak',
  'naing',
  'rejeh',
  'kajen',
  'pukir',
  'kulen',
  'kantu',
  'tipet',
  'mabur',
  'gunah',
  'unyet',
  'tohan',
  'nerod',
  'sayad',
  'sahir',
  'gilap',
  'neker',
  'gesan',
  'pupon',
  'sekar',
  'dudut',
  'cukat',
  'kusuk',
  'kento',
  'witir',
  'athok',
  'mlaha',
  'impur',
  'sisan',
  'bobad',
  'pogok',
  'pirik',
  'druta',
  'mepro',
  'antar',
  'nrima',
  'romal',
  'edhel',
  'koser',
  'kirti',
  'opyak',
  'rampa',
  'senuk',
  'lurub',
  'rahsa',
  'meles',
  'salap',
  'mirma',
  'mabok',
  'rekan',
  'menda',
  'wijah',
  'mulku',
  'racah',
  'atrap',
  'walya',
  'pekeh',
  'kleci',
  'icrit',
  'rampo',
  'jetun',
  'kabul',
  'jegal',
  'turun',
  'dilah',
  'mukok',
  'ceced',
  'tobat',
  'kenji',
  'saong',
  'nekad',
  'bekur',
  'besel',
  'magru',
  'kelus',
  'suguh',
  'kecok',
  'cagak',
  'salit',
  'pegin',
  'sulur',
  'berek',
  'lawih',
  'capil',
  'gegoh',
  'mijil',
  'begen',
  'dakar',
  'iling',
  'pileh',
  'nagri',
  'barak',
  'tunas',
  'jelit',
  'mudul',
  'wulan',
  'komet',
  'gesek',
  'duweh',
  'mandi',
  'lapli',
  'celik',
  'saged',
  'netep',
  'mesud',
  'rigis',
  'koyup',
  'bules',
  'pilah',
  'anrus',
  'aslup',
  'nutuh',
  'kerid',
  'barja',
  'pecut',
  'jitus',
  'marug',
  'sepai',
  'liwar',
  'surak',
  'guyur',
  'terop',
  'beker',
  'suwuk',
  'serwa',
  'mlowa',
  'parem',
  'warsa',
  'padha',
  'bantu',
  'dlole',
  'nipar',
  'kasuh',
  'puthu',
  'dares',
  'tiris',
  'akweh',
  'tuwuh',
  'idhup',
  'kohen',
  'susah',
  'siswa',
  'jatos',
  'pepeg',
  'mlaku',
  'jeber',
  'derap',
  'nawur',
  'sibat',
  'nesah',
  'badan',
  'siksa',
  'ebleg',
  'gogol',
  'orong',
  'getih',
  'jemuk',
  'pecoh',
  'dhagu',
  'tambi',
  'kempi',
  'plung',
  'mehak',
  'layap',
  'yekti',
  'kamud',
  'grong',
  'unyal',
  'demak',
  'jegot',
  'gruwa',
  'wunut',
  'ikang',
  'joluk',
  'kapuk',
  'entar',
  'nabda',
  'sikil',
  'wantu',
  'jebos',
  'kamus',
  'tobil',
  'nepyu',
  'larug',
  'dyuta',
  'sepuh',
  'cucuh',
  'madal',
  'antop',
  'ropoh',
  'torap',
  'dukut',
  'nayab',
  'cegat',
  'babuk',
  'biluk',
  'pusus',
  'lojok',
  'misra',
  'rarab',
  'bibir',
  'jepit',
  'ungsi',
  'watos',
  'sawer',
  'panji',
  'uring',
  'nempe',
  'kacep',
  'bujuk',
  'baluh',
  'kicak',
  'kidul',
  'pekir',
  'ampil',
  'kekel',
  'bogoh',
  'usaha',
  'umbal',
  'enyak',
  'kaper',
  'jukut',
  'matar',
  'bujil',
  'raton',
  'daran',
  'ebyak',
  'sagem',
  'negin',
  'dubur',
  'molor',
  'jogol',
  'bihal',
  'mubed',
  'kemul',
  'banci',
  'waila',
  'jalon',
  'werat',
  'welit',
  'nyong',
  'papon',
  'odheg',
  'dhela',
  'mesin',
  'baneh',
  'marus',
  'bakoh',
  'waneh',
  'bakyu',
  'papas',
  'beweh',
  'encet',
  'tonyo',
  'manuk',
  'kakim',
  'naptu',
  'mepeh',
  'westa',
  'nahan',
  'keyok',
  'paris',
  'taren',
  'pusak',
  'ninek',
  'udhik',
  'wahil',
  'liyep',
  'sirih',
  'rubuk',
  'panek',
  'cebak',
  'setra',
  'keksi',
  'jinis',
  'palar',
  'magek',
  'kacor',
  'nebal',
  'tepuk',
  'mabuk',
  'delep',
  'kamal',
  'gabah',
  'mipit',
  'ringa',
  'misil',
  'lawis',
  'sadat',
  'epang',
  'nakur',
  'mawug',
  'taota',
  'kwasa',
  'oncit',
  'risak',
  'jreng',
  'selon',
  'layur',
  'pijig',
  'boran',
  'jeple',
  'sisih',
  'ketog',
  'pokal',
  'cekut',
  'suled',
  'netel',
  'jaban',
  'liwer',
  'negel',
  'kepok',
  'lesoh',
  'rayah',
  'tikep',
  'yakut',
  'sigar',
  'kesud',
  'logat',
  'nekek',
  'latri',
  'jantu',
  'lanas',
  'geyan',
  'tawas',
  'udhip',
  'rebda',
  'setek',
  'bapak',
  'pecat',
  'loket',
  'wawan',
  'prika',
  'poret',
  'totos',
  'lebon',
  'basih',
  'sukan',
  'tlale',
  'semek',
  'resep',
  'ancul',
  'mukah',
  'kapan',
  'jumot',
  'upala',
  'sulam',
  'reseg',
  'jobin',
  'togog',
  'astha',
  'wujud',
  'neteg',
  'amang',
  'kawak',
  'layat',
  'besta',
  'colot',
  'ancik',
  'jatha',
  'tegos',
  'miswa',
  'pened',
  'gudel',
  'kuwil',
  'reyan',
  'galur',
  'peped',
  'jirap',
  'dukek',
  'gegen',
  'negal',
  'ubaya',
  'wiswa',
  'endhe',
  'bulak',
  'entre',
  'suwel',
  'joane',
  'cakra',
  'susut',
  'napis',
  'antya',
  'nyasa',
  'ngoso',
  'tetah',
  'rungu',
  'pelik',
  'bakah',
  'samir',
  'mriki',
  'retur',
  'jukur',
  'nyita',
  'patni',
  'babuh',
  'uncal',
  'cotho',
  'pegon',
  'embel',
  'luyut',
  'ratah',
  'joglo',
  'bahan',
  'masmu',
  'korap',
  'pelor',
  'jubad',
  'buyut',
  'jimat',
  'kasil',
  'preta',
  'nodhi',
  'celak',
  'cepek',
  'antos',
  'belek',
  'tapet',
  'cemok',
  'klawe',
  'kenca',
  'gelem',
  'ragum',
  'cakar',
  'tutur',
  'emung',
  'lempe',
  'bojod',
  'belor',
  'kawon',
  'suwit',
  'kidam',
  'neken',
  'cicig',
  'kaspe',
  'kidhe',
  'balad',
  'gobed',
  'matuk',
  'buyar',
  'tirta',
  'abrik',
  'koyor',
  'gubis',
  'botol',
  'danti',
  'etrap',
  'kajog',
  'kudus',
  'lipur',
  'kesuh',
  'kalot',
  'rudah',
  'bipet',
  'metha',
  'organ',
  'merat',
  'gilar',
  'krapa',
  'pamek',
  'dadah',
  'meper',
  'jegur',
  'semah',
  'wulat',
  'nojoh',
  'repit',
  'undhi',
  'sedya',
  'kisut',
  'tebah',
  'nalib',
  'keteb',
  'wegil',
  'glali',
  'krawu',
  'galih',
  'mlana',
  'karad',
  'nukil',
  'rebut',
  'alang',
  'sisig',
  'jagal',
  'parot',
  'jegog',
  'cepon',
  'jleng',
  'coker',
  'cucah',
  'grati',
  'cicih',
  'insap',
  'keges',
  'lekoh',
  'megan',
  'gulon',
  'kicir',
  'uncar',
  'batak',
  'welug',
  'sarga',
  'jurus',
  'punis',
  'kecai',
  'donga',
  'garuk',
  'ratan',
  'muwun',
  'nyapu',
  'dhaku',
  'kerok',
  'midek',
  'tulya',
  'dodol',
  'kewer',
  'atika',
  'jyoti',
  'kiyal',
  'capit',
  'enges',
  'umreg',
  'towok',
  'menet',
  'paksi',
  'lisuh',
  'srada',
  'guyub',
  'naruk',
  'kejep',
  'cukak',
  'jabeg',
  'rumpi',
  'jujag',
  'anyep',
  'cekak',
  'dulek',
  'taloh',
  'tebek',
  'bunuh',
  'marah',
  'kasir',
  'rasul',
  'romot',
  'curut',
  'jatya',
  'lijok',
  'bolet',
  'nedhi',
  'moros',
  'bulet',
  'soksi',
  'ingis',
  'gloso',
  'timur',
  'canda',
  'gusak',
  'kowok',
  'likur',
  'tatak',
  'engka',
  'ratib',
  'kukul',
  'keper',
  'carya',
  'gawil',
  'telad',
  'johan',
  'kerah',
  'nyang',
  'selat',
  'rimbu',
  'kowen',
  'tarwa',
  'cicik',
  'masuk',
  'serga',
  'ambul',
  'kulem',
  'kerab',
  'sepit',
  'purih',
  'wunuh',
  'gopok',
  'batih',
  'susuh',
  'kebat',
  'nacak',
  'maluh',
  'kojur',
  'kreta',
  'jeleh',
  'jeles',
  'peceh',
  'sayur',
  'dhere',
  'majer',
  'babag',
  'plecu',
  'kapas',
  'majir',
  'jujul',
  'karag',
  'ganan',
  'wulya',
  'ginau',
  'dewan',
  'cojoh',
  'sasti',
  'gayas',
  'tawap',
  'rimba',
  'cemer',
  'mijet',
  'kawah',
  'purna',
  'sugih',
  'gupoh',
  'celep',
  'melik',
  'plalu',
  'menje',
  'hrada',
  'dagel',
  'sawak',
  'kerog',
  'abrut',
  'lolor',
  'neter',
  'kapya',
  'werit',
  'nauri',
  'kebus',
  'binah',
  'ungak',
  'gabar',
  'piyik',
  'talek',
  'wurik',
  'gocel',
  'wedya',
  'bresi',
  'uncul',
  'pelet',
  'talub',
  'rodok',
  'homan',
  'bibis',
  'gloco',
  'andon',
  'inter',
  'ranta',
  'kelim',
  'serub',
  'ikram',
  'irang',
  'rigal',
  'badha',
  'nguni',
  'jejel',
  'kulon',
  'legen',
  'pahat',
  'codya',
  'entup',
  'nyata',
  'gelek',
  'kopak',
  'repek',
  'matis',
  'rejeb',
  'gelap',
  'ratya',
  'undur',
  'jubel',
  'cuwak',
  'pagon',
  'tamak',
  'jenis',
  'binte',
  'entol',
  'gapah',
  'tulah',
  'limut',
  'manja',
  'ander',
  'andel',
  'jegug',
  'rigma',
  'ywang',
  'gulet',
  'wafat',
  'jawen',
  'pusuh',
  'selak',
  'katen',
  'merdi',
  'cukur',
  'nagih',
  'takwa',
  'makal',
  'jebug',
  'pijir',
  'ampoh',
  'paido',
  'dimen',
  'besan',
  'dalit',
  'kulak',
  'bacem',
  'sakta',
  'birid',
  'mukul',
  'bapuh',
  'runya',
  'swasa',
  'jinem',
  'jarum',
  'bejog',
  'tlaga',
  'jawan',
  'meges',
  'purun',
  'gisau',
  'tedah',
  'kelip',
  'ngrik',
  'wadhu',
  'lawar',
  'batis',
  'lepen',
  'suwak',
  'embur',
  'seksi',
  'nenja',
  'kawal',
  'madak',
  'lusuh',
  'jorog',
  'necis',
  'memel',
  'pipit',
  'odrog',
  'juwut',
  'satak',
  'gabul',
  'lampu',
  'wiyar',
  'ngidu',
  'nyeda',
  'kacap',
  'cecer',
  'jirak',
  'minge',
  'segok',
  'tabel',
  'cupar',
  'traca',
  'malah',
  'rukma',
  'geret',
  'pardi',
  'turut',
  'tohid',
  'sutra',
  'jebet',
  'ingga',
  'gayat',
  'tabib',
  'ropek',
  'bubuk',
  'nyait',
  'ngera',
  'ether',
  'sulit',
  'nepak',
  'akram',
  'malik',
  'celup',
  'susur',
  'srutu',
  'ginem',
  'keruh',
  'kelit',
  'embus',
  'mujuh',
  'kisma',
  'seset',
  'onyog',
  'teleh',
  'uncit',
  'tebok',
  'sebat',
  'sayan',
  'gadhu',
  'lemon',
  'janti',
  'ambra',
  'tikta',
  'rawuh',
  'saweg',
  'luwah',
  'usada',
  'wowog',
  'babit',
  'gecol',
  'racik',
  'uncet',
  'ruwed',
  'rabda',
  'keweh',
  'nojeh',
  'ebyar',
  'remus',
  'togen',
  'nyolo',
  'palud',
  'pered',
  'bedru',
  'engel',
  'sulah',
  'lemir',
  'reang',
  'cempa',
  'kwaci',
  'cuwan',
  'tajem',
  'ajrih',
  'nalar',
  'sukup',
  'bendu',
  'wisya',
  'warih',
  'mliwe',
  'mingi',
  'beton',
  'tinjo',
  'utawi',
  'paweh',
  'marek',
  'sumbu',
  'anapi',
  'lisig',
  'tosan',
  'daleh',
  'gesil',
  'munci',
  'kusta',
  'silem',
  'popok',
  'biyak',
  'adhuk',
  'pecus',
  'kenir',
  'gerih',
  'magon',
  'lepar',
  'ikluk',
  'panco',
  'narka',
  'samad',
  'obrol',
  'pajeg',
  'barji',
  'logro',
  'gotri',
  'camra',
  'jajal',
  'malim',
  'prene',
  'sabet',
  'caruk',
  'meleg',
  'lumur',
  'gudag',
  'lewar',
  'kalap',
  'kakab',
  'telik',
  'purir',
  'genem',
  'kutha',
  'lamad',
  'gawer',
  'kolem',
  'selog',
  'kijab',
  'natas',
  'lorop',
  'pesud',
  'sesak',
  'andha',
  'kelas',
  'halep',
  'pamer',
  'ngecu',
  'huruf',
  'bimba',
  'wader',
  'pelut',
  'kates',
  'muthu',
  'ebong',
  'edhak',
  'kebas',
  'puteh',
  'mupak',
  'swawi',
  'keter',
  'lejeg',
  'seger',
  'aleng',
  'agama',
  'jukok',
  'wilet',
  'ganja',
  'untup',
  'kucur',
  'waluh',
  'biyet',
  'cerah',
  'bubar',
  'cugag',
  'kular',
  'plong',
  'coled',
  'rinto',
  'tampu',
  'situn',
  'pohan',
  'mamuk',
  'torog',
  'leleh',
  'murad',
  'susul',
  'maosi',
  'ciyut',
  'murih',
  'enjuk',
  'tahan',
  'lamur',
  'egleh',
  'kumbu',
  'engik',
  'beruh',
  'katel',
  'nuruh',
  'saben',
  'badra',
  'prasa',
  'gusti',
  'pecah',
  'teras',
  'tawon',
  'piatu',
  'tenga',
  'garot',
  'mimba',
  'tehak',
  'bolor',
  'kolak',
  'games',
  'riwit',
  'negar',
  'busik',
  'dekna',
  'rewod',
  'ceket',
  'wakil',
  'dalih',
  'tingi',
  'mahat',
  'kagak',
  'dlolo',
  'lekik',
  'sajeg',
  'praba',
  'hakim',
  'pangu',
  'sredu',
  'walon',
  'asthi',
  'jimur',
  'gadug',
  'benak',
  'seser',
  'mutik',
  'jibeg',
  'suluk',
  'bejeg',
  'kretu',
  'balok',
  'kleru',
  'athuh',
  'akang',
  'samsu',
  'embik',
  'peksa',
  'bagel',
  'bekah',
  'basar',
  'silep',
  'bedhe',
  'tengu',
  'yeksi',
  'murti',
  'muwal',
  'sadak',
  'daler',
  'premi',
  'caoto',
  'kawit',
  'ngaru',
  'kiyek',
  'lonyo',
  'jireh',
  'kuren',
  'wudun',
  'tabuh',
  'lejem',
  'pisin',
  'manik',
  'purba',
  'dadya',
  'cacak',
  'gadag',
  'sertu',
  'derah',
  'rewok',
  'muruh',
  'widik',
  'udata',
  'totol',
  'mugal',
  'daweg',
  'mloco',
  'angsu',
  'selag',
  'selot',
  'bulug',
  'mirud',
  'kegok',
  'mokel',
  'blawe',
  'bahak',
  'layan',
  'suhud',
  'tijab',
  'nusur',
  'idhum',
  'cipir',
  'greng',
  'untas',
  'lipya',
  'cerit',
  'ancak',
  'nebah',
  'taker',
  'conok',
  'ingan',
  'bebek',
  'kasut',
  'nimna',
  'bebeg',
  'kodag',
  'marta',
  'benci',
  'winor',
  'lulud',
  'kopok',
  'siwul',
  'watek',
  'temtu',
  'jibir',
  'tisik',
  'kapti',
  'wedha',
  'gulan',
  'jadhi',
  'nulak',
  'repoh',
  'cekok',
  'monte',
  'kirat',
  'sarib',
  'manis',
  'nereg',
  'upama',
  'kejen',
  'wiyak',
  'jamur',
  'polos',
  'rasuk',
  'mreti',
  'tayoh',
  'basma',
  'pesok',
  'wrana',
  'jejep',
  'kiwil',
  'wisik',
  'meded',
  'sukma',
  'senet',
  'marna',
  'siram',
  'baron',
  'greja',
  'kacer',
  'gabig',
  'powan',
  'itung',
  'pelur',
  'sitah',
  'tawan',
  'tiwas',
  'teher',
  'kelun',
  'nanah',
  'miber',
  'tamba',
  'meyos',
  'rebah',
  'cring',
  'racak',
  'besik',
  'rakwa',
  'sigra',
  'saban',
  'kawya',
  'awrat',
  'mujur',
  'yakti',
  'puyer',
  'musuh',
  'mical',
  'cecel',
  'ngepe',
  'cokeh',
  'betho',
  'bilas',
  'mujuk',
  'supit',
  'tenge',
  'nying',
  'netra',
  'ligur',
  'gagas',
  'dwesa',
  'edheg',
  'gaman',
  'kirik',
  'siten',
  'macit',
  'kuwih',
  'wigar',
  'lembu',
  'ciruk',
  'birai',
  'baksa',
  'kiyas',
  'nahas',
  'pipis',
  'ancab',
  'nyewu',
  'windu',
  'tahap',
  'lagar',
  'sasat',
  'rukem',
  'tasih',
  'aling',
  'emeng',
  'ethur',
  'calek',
  'mawal',
  'cojog',
  'tliti',
  'burus',
  'gaduk',
  'entra',
  'jurit',
  'neces',
  'tetak',
  'nyuku',
  'polah',
  'gagut',
  'kapok',
  'jadah',
  'nisab',
  'mukun',
  'lepet',
  'sineh',
  'singa',
  'mukta',
  'gawok',
  'pokok',
  'kesur',
  'ratak',
  'gabro',
  'ciyet',
  'kanem',
  'lolya',
  'dhana',
  'gimer',
  'oling',
  'gerba',
  'baret',
  'ekrar',
  'ngelo',
  'rakmi',
  'rapak',
  'solok',
  'unyik',
  'menir',
  'papat',
  'tujwa',
  'lesya',
  'dreng',
  'nekem',
  'pelot',
  'donok',
  'makam',
  'beber',
  'kumba',
  'kriya',
  'klesa',
  'kanas',
  'paluh',
  'parat',
  'terah',
  'puntu',
  'nyuwe',
  'luteh',
  'cakut',
  'biyen',
  'cerma',
  'keled',
  'badhe',
  'nitir',
  'robet',
  'pejuh',
  'teple',
  'dopok',
  'yayah',
  'wokan',
  'bajra',
  'pacet',
  'guwul',
  'kapep',
  'gugup',
  'taoge',
  'kapir',
  'sasur',
  'luwas',
  'ampad',
  'salat',
  'biren',
  'mumut',
  'nyaba',
  'kinar',
  'keket',
  'plara',
  'kikil',
  'tolek',
  'matut',
  'pojar',
  'rohot',
  'tutus',
  'gajah',
  'borok',
  'kulam',
  'tekte',
  'loper',
  'ubyek',
  'sakal',
  'ublak',
  'comot',
  'adhep',
  'patik',
  'dhasi',
  'ruket',
  'tikin',
  'mrema',
  'warid',
  'ngulu',
  'kekes',
  'siwar',
  'tubar',
  'yatma',
  'yatim',
  'terap',
  'natya',
  'rajya',
  'galon',
  'tirok',
  'ngaku',
  'keked',
  'bikak',
  'mehan',
  'sarem',
  'serep',
  'gesin',
  'gabus',
  'dahat',
  'gones',
  'jiguh',
  'damar',
  'ngura',
  'kedah',
  'gogos',
  'basah',
  'bijig',
  'rewan',
  'krega',
  'murah',
  'kompa',
  'baleg',
  'kebos',
  'jambe',
  'jebir',
  'racuk',
  'werdi',
  'dikne',
  'tepos',
  'murat',
  'brati',
  'ramal',
  'rubed',
  'serop',
  'sabin',
  'usung',
  'tumeg',
  'ngawi',
  'klinu',
  'sinta',
  'kesik',
  'muyeg',
  'kawul',
  'anjir',
  'saoto',
  'jubag',
  'bawak',
  'ungas',
  'mriyi',
  'kekep',
  'pelok',
  'gurah',
  'jisin',
  'kasak',
  'repot',
  'among',
  'mraja',
  'leres',
  'napas',
  'pulut',
  'tiyam',
  'cakep',
  'nitih',
  'limer',
  'netek',
  'porot',
  'tagih',
  'ngapu',
  'gered',
  'tereh',
  'maret',
  'cerek',
  'tebus',
  'netes',
  'timun',
  'napih',
  'puwas',
  'jebol',
  'gosok',
  'demok',
  'wahyu',
  'sipin',
  'jalir',
  'tales',
  'siwil',
  'syapa',
  'gigat',
  'kunca',
  'dhapa',
  'lower',
  'patuk',
  'tegar',
  'sayuk',
  'tabir',
  'mergi',
  'kosus',
  'tened',
  'lemek',
  'sanes',
  'wedon',
  'gites',
  'tampi',
  'areng',
  'suson',
  'sikir',
  'kitir',
  'yakku',
  'bobag',
  'bothi',
  'dhagi',
  'antuk',
  'endak',
  'cenes',
  'krasa',
  'diren',
  'pegot',
  'titen',
  'wenah',
  'pones',
  'twang',
  'tutuh',
  'lepca',
  'peluh',
  'prawa',
  'koceh',
  'bonto',
  'sudha',
  'mriku',
  'sloki',
  'lecun',
  'milet',
  'sogok',
  'riwil',
  'mules',
  'gusis',
  'kobar',
  'lajur',
  'papan',
  'brani',
  'suwau',
  'kesut',
  'junub',
  'angub',
  'cumur',
  'sosot',
  'sipet',
  'gagak',
  'megeg',
  'plato',
  'rehne',
  'pocel',
  'leled',
  'culek',
  'katos',
  'sarsa',
  'najin',
  'cures',
  'jilep',
  'dhaga',
  'mukir',
  'pacek',
  'sanja',
  'sumbi',
  'pasuk',
  'sibin',
  'prewe',
  'tongo',
  'winih',
  'dhaut',
  'getas',
  'rogoh',
  'kebak',
  'jalma',
  'salin',
  'sunga',
  'bumbu',
  'masar',
  'nuswa',
  'rasmi',
  'craki',
  'masem',
  'derma',
  'tebas',
  'bekuh',
  'gayam',
  'tojoh',
  'telep',
  'cirit',
  'beluk',
  'pinge',
  'teyer',
  'wagal',
  'korak',
  'getap',
  'giris',
  'jimot',
  'mrasa',
  'ajeng',
  'parul',
  'ampeh',
  'mijah',
  'becer',
  'lekak',
  'wakya',
  'untut',
  'masak',
  'welah',
  'rijal',
  'neksa',
  'sring',
  'kopen',
  'sosol',
  'wutun',
  'logok',
  'neket',
  'delek',
  'pakir',
  'timba',
  'tageh',
  'tuwuk',
  'mebah',
  'galar',
  'mrega',
  'tekis',
  'koplo',
  'bikut',
  'encot',
  'parah',
  'memba',
  'ramen',
  'babok',
  'irama',
  'greba',
  'kukur',
  'mogat',
  'ngeja',
  'pribe',
  'trasi',
  'puman',
  'klici',
  'sunan',
  'lotre',
  'kukub',
  'wudel',
  'tilep',
  'awang',
  'embah',
  'nanas',
  'lirig',
  'nebut',
  'ranju',
  'dudul',
  'muruk',
  'rucek',
  'larik',
  'cuget',
  'swana',
  'wyaya',
  'larap',
  'laruh',
  'mukim',
  'anggo',
  'necer',
  'kasol',
  'lebih',
  'lewer',
  'meter',
  'barci',
  'kobet',
  'laleh',
  'rupek',
  'usadi',
  'ketut',
  'ijmak',
  'nahen',
  'mulut',
  'sruwe',
  'sekti',
  'remih',
  'weloh',
  'meped',
  'gigit',
  'ngina',
  'rijuh',
  'othak',
  'tumpa',
  'pilag',
  'lobis',
  'muwel',
  'masih',
  'jarik',
  'lowok',
  'silak',
  'rikat',
  'ethos',
  'keles',
  'wasta',
  'ingel',
  'gasab',
  'bacuk',
  'jijal',
  'thawe',
  'milah',
  'jagul',
  'jiyad',
  'bener',
  'nusya',
  'umres',
  'rajab',
  'terka',
  'wawar',
  'kecet',
  'jubeg',
  'mihun',
  'ngala',
  'pasog',
  'craek',
  'saraf',
  'gubug',
  'dyayi',
  'sayab',
  'warah',
  'balik',
  'ratus',
  'cabut',
  'nliti',
  'liket',
  'untel',
  'embes',
  'odhet',
  'kikuk',
  'hasil',
  'theng',
  'tuhan',
  'gawel',
  'manet',
  'serum',
  'punel',
  'letre',
  'kawel',
  'rujit',
  'emput',
  'thole',
  'busak',
  'neseg',
  'glong',
  'kenut',
  'wadok',
  'sesek',
  'mlala',
  'lebuh',
  'talam',
  'embat',
  'jinah',
  'odhil',
  'wreda',
  'waler',
  'potra',
  'rogol',
  'rosok',
  'hotel',
  'losin',
  'rusak',
  'ombol',
  'gelik',
  'banar',
  'pakan',
  'kleja',
  'tajin',
  'belot',
  'rapih',
  'cawil',
  'jereh',
  'clili',
  'gusek',
  'gesot',
  'endan',
  'welad',
  'kecus',
  'untal',
  'ketab',
  'radya',
  'ngoto',
  'jugil',
  'deruk',
  'jamus',
  'derbe',
  'lesus',
  'imbuh',
  'onder',
  'jojoh',
  'mulat',
  'serju',
  'jager',
  'walak',
  'rukuk',
  'rakup',
  'ganda',
  'miles',
  'yatku',
  'oprup',
  'sunge',
  'cekel',
  'rujad',
  'grija',
  'ngono',
  'siwal',
  'abang',
  'wakul',
  'oncor',
  'kedeh',
  'dhono',
  'bekem',
  'sawab',
  'kawuk',
  'mimis',
  'kayas',
  'wisuh',
  'wiben',
  'nulya',
  'teksa',
  'molah',
  'baluk',
  'sumer',
  'anjap',
  'tomis',
  'nepus',
  'mecah',
  'simak',
  'anjat',
  'koran',
  'upata',
  'bajul',
  'licik',
  'natur',
  'gupak',
  'nakal',
  'ebrug',
  'misin',
  'guluh',
  'kluwa',
  'calon',
  'lindi',
  'rawit',
  'cebuk',
  'nerus',
  'sebda',
  'sipir',
  'ramak',
  'grema',
  'petha',
  'ruwid',
  'lukis',
  'marga',
  'piyak',
  'jabal',
  'nasab',
  'rigol',
  'mesan',
  'simet',
  'nunut',
  'cabul',
  'jling',
  'ritek',
  'latha',
  'namik',
  'tatar',
  'bolos',
  'jebor',
  'sawed',
  'berod',
  'pados',
  'dimer',
  'wreta',
  'sapih',
  'pacak',
  'kesya',
  'silah',
  'pacuh',
  'gipit',
  'tabok',
  'silib',
  'sakur',
  'wuwur',
  'rahim',
  'tluka',
  'nonyo',
  'dugan',
  'kadek',
  'lawat',
  'becek',
  'ngaos',
  'tahen',
  'lowah',
  'nelad',
  'kayon',
  'jawat',
  'nelak',
  'brahi',
  'bebeh',
  'mijir',
  'ustra',
  'gempo',
  'sulap',
  'blaes',
  'payah',
  'blaba',
  'ubrus',
  'dleya',
  'kilem',
  'plang',
  'ikana',
  'peyik',
  'lodok',
  'lunas',
  'nenga',
  'limpa',
  'lagak',
  'sebae',
  'durga',
  'japan',
  'boled',
  'triko',
  'kecel',
  'mekak',
  'weyan',
  'yasti',
  'kunta',
  'reyog',
  'cidra',
  'ritak',
  'lawuh',
  'sabil',
  'poyok',
  'dupih',
  'naroh',
  'lalar',
  'libur',
  'mirah',
  'pasok',
  'suker',
  'regak',
  'mijer',
  'paras',
  'juwal',
  'nocor',
  'narik',
  'mapak',
  'lombo',
  'wedhi',
  'estri',
  'niyat',
  'nocok',
  'ratuh',
  'paron',
  'janma',
  'leweh',
  'repet',
  'paila',
  'gubed',
  'kokoh',
  'ngelu',
  'meken',
  'mapag',
  'druju',
  'bocor',
  'kepik',
  'dimok',
  'mimik',
  'krama',
  'upyek',
  'kidon',
  'nayoh',
  'istha',
  'pulen',
  'nacal',
  'juran',
  'melot',
  'kanal',
  'tanek',
  'ulung',
  'yadin',
  'nesir',
  'ronce',
  'kelor',
  'pante',
  'wyadi',
  'mirid',
  'tayuh',
  'glape',
  'nyara',
  'srang',
  'berem',
  'giyer',
  'tegah',
  'nurun',
  'mamak',
  'kumed',
  'kopar',
  'jibur',
  'kolon',
  'mudal',
  'amban',
  'kudon',
  'pajut',
  'soter',
  'patih',
  'bakar',
  'rebek',
  'ewang',
  'setut',
  'ekrok',
  'jegil',
  'wapat',
  'redhu',
  'likat',
  'blilu',
  'bojog',
  'latar',
  'gupit',
  'telah',
  'lalah',
  'rotuh',
  'angka',
  'lopis',
  'bacot',
  'lumbu',
  'mucal',
  'cepeh',
  'belan',
  'dunga',
  'angob',
  'lalis',
  'sekop',
  'pilih',
  'nabah',
  'pirsa',
  'adres',
  'reweg',
  'merad',
  'beles',
  'polir',
  'waloh',
  'kasud',
  'rapah',
  'lunta',
  'pwasa',
  'kagok',
  'kisen',
  'lirip',
  'kopet',
  'punuk',
  'selah',
  'gebal',
  'marak',
  'pikir',
  'sapet',
  'muksa',
  'unjuk',
  'mekca',
  'widak',
  'rimis',
  'magen',
  'dhesi',
  'natos',
  'songa',
  'lejar',
  'mogel',
  'jidar',
  'nyali',
  'japit',
  'domba',
  'enyah',
  'surat',
  'prono',
  'rucuh',
  'topan',
  'cowek',
  'jrong',
  'ogreg',
  'pelat',
  'pasir',
  'kratu',
  'ahlen',
  'jring',
  'apsah',
  'barus',
  'rikuh',
  'mlara',
  'rajin',
  'kocek',
  'aglis',
  'puluk',
  'trini',
  'orkes',
  'masah',
  'kwaya',
  'bagal',
  'nyare',
  'inyak',
  'telag',
  'lecek',
  'asrah',
  'celet',
  'rilak',
  'moksa',
  'kiwul',
  'kitri',
  'preen',
  'amper',
  'kepon',
  'marem',
  'dalon',
  'kepek',
  'tekok',
  'jegeg',
  'keruk',
  'gasir',
  'sabda',
  'akara',
  'ekral',
  'tolok',
  'galan',
  'rawis',
  'melas',
  'kuwen',
  'midih',
  'sadya',
  'garem',
  'cepel',
  'nalak',
  'krabu',
  'neges',
  'payon',
  'kerja',
  'nebak',
  'gelah',
  'rebon',
  'cethi',
  'swami',
  'panon',
  'puspa',
  'pegel',
  'gejah',
  'wuruh',
  'tatah',
  'cepet',
  'mader',
  'ngisi',
  'leger',
  'telas',
  'lemes',
  'angon',
  'sekel',
  'mlele',
  'welut',
  'kabar',
  'jemot',
  'nabet',
  'dunya',
  'mojok',
  'kewat',
  'murun',
  'tyang',
  'geyet',
  'labah',
  'bikir',
  'surup',
  'entes',
  'onyol',
  'gotho',
  'mulwa',
  'pisak',
  'walek',
  'purak',
  'gotek',
  'kecon',
  'batek',
  'kejem',
  'rames',
  'sukle',
  'bunel',
  'yuswa',
  'hibek',
  'nyoga',
  'yuyun',
  'nipah',
  'sasag',
  'meweh',
  'sopak',
  'judhi',
  'komoh',
  'grago',
  'latuh',
  'kakek',
  'jenar',
  'gisit',
  'gaten',
  'bedor',
  'aryan',
  'pukul',
  'merem',
  'kelos',
  'neher',
  'mayan',
  'juwet',
  'tipas',
  'pinti',
  'melid',
  'soroh',
  'bindi',
  'tinon',
  'nipis',
  'latah',
  'meret',
  'haram',
  'rogul',
  'wedra',
  'angut',
  'duweg',
  'tilam',
  'galer',
  'makan',
  'plucu',
  'limpe',
  'jepun',
  'eplek',
  'wagel',
  'kenil',
  'pocuk',
  'klair',
  'kepeh',
  'wikan',
  'guweh',
  'prime',
  'lajuk',
  'krang',
  'rewed',
  'merak',
  'mufti',
  'katib',
  'berik',
  'kepet',
  'gugat',
  'tirem',
  'sereh',
  'suyud',
  'eblek',
  'tilap',
  'tiksa',
  'krimi',
  'rubuh',
  'unyer',
  'ariya',
  'pinis',
  'mlayu',
  'uwong',
  'andik',
  'ketes',
  'tijeh',
  'groda',
  'radon',
  'murub',
  'yitna',
  'urdah',
  'liwet',
  'anjun',
  'nangi',
  'jarah',
  'bebel',
  'teneh',
  'cawuk',
  'iblis',
  'yaksi',
  'dilek',
  'kesah',
  'dekep',
  'sirem',
  'ranap',
  'gebes',
  'butul',
  'tanem',
  'lumer',
  'gerul',
  'suwur',
  'kuwuk',
  'pacel',
  'graos',
  'kaput',
  'durek',
  'puleh',
  'tyagi',
  'guguk',
  'kurih',
  'keset',
  'mejer',
  'gilig',
  'gemes',
  'kamas',
  'gemuh',
  'urmat',
  'nurut',
  'swiwi',
  'empas',
  'luput',
  'danas',
  'napak',
  'wesma',
  'prada',
  'gamar',
  'suluh',
  'rujag',
  'embir',
  'medan',
  'tetes',
  'grasi',
  'katut',
  'manuh',
  'ngeni',
  'jaket',
  'najis',
  'ember',
  'mamah',
  'unjar',
  'riyom',
  'guruh',
  'nuduh',
  'sered',
  'muwur',
  'milok',
  'suhul',
  'turna',
  'gepor',
  'hajat',
  'anges',
  'keleg',
  'sapar',
  'kelot',
  'lutak',
  'potok',
  'penal',
  'takad',
  'serak',
  'kokop',
  'athuk',
  'sumeh',
  'jebod',
  'nampi',
  'brene',
  'luwes',
  'garon',
  'septu',
  'gecem',
  'lalos',
  'anyur',
  'umbar',
  'lalen',
  'ngaub',
  'jobag',
  'negor',
  'karug',
  'gerok',
  'patwa',
  'loyop',
  'kweni',
  'gosek',
  'ikrik',
  'takir',
  'asten',
  'segah',
  'polor',
  'muntu',
  'atiti',
  'pater',
  'kewan',
  'garan',
  'taken',
  'yaudi',
  'silit',
  'nraju',
  'nisir',
  'dlape',
  'duren',
  'keram',
  'tetek',
  'pepel',
  'baloh',
  'tomah',
  'kikid',
  'meski',
  'reweh',
  'goweh',
  'dabag',
  'mauki',
  'dibya',
  'mutra',
  'senti',
  'ganep',
  'rangu',
  'nlika',
  'lulus',
  'kucai',
  'renuk',
  'muteh',
  'jemak',
  'aming',
  'ponis',
  'joged',
  'nidra',
  'ences',
  'marmi',
  'cumpu',
  'catur',
  'tajab',
  'cikal',
  'wukir',
  'mirik',
  'wagra',
  'gebel',
  'angik',
  'wlaha',
  'kiyai',
  'silir',
  'nonah',
  'regen',
  'gugur',
  'pecak',
  'kebau',
  'susup',
  'kukuh',
  'rajah',
  'karta',
  'rodha',
  'tepes',
  'mukya',
  'ethok',
  'cowag',
  'kubul',
  'pikat',
  'othik',
  'terag',
  'namun',
  'durta',
  'sepoh',
  'tilem',
  'getir',
  'nonor',
  'lacut',
  'wejag',
  'bonen',
  'teluh',
  'terok',
  'wande',
  'miyat',
  'ancel',
  'gales',
  'tampa',
  'mupuh',
  'carem',
  'nyato',
  'barik',
  'wawuh',
  'adhuh',
  'kaget',
  'bukur',
  'wowor',
  'sumub',
  'jirna',
  'mepes',
  'panti',
  'tulak',
  'kocok',
  'smita',
  'akbar',
  'matil',
  'kante',
  'krana',
  'rukmi',
  'sabuk',
  'ciwek',
  'ngaki',
  'kipat',
  'tenan',
  'ungup',
  'serot',
  'putha',
  'upret',
  'jedhi',
  'tuwak',
  'jegen',
  'ratri',
  'kupat',
  'nurat',
  'robah',
  'memek',
  'jagir',
  'wurda',
  'merel',
  'puwan',
  'injoh',
  'mastu',
  'mraba',
  'rempo',
  'eneng',
  'larad',
  'lutih',
  'tabur',
  'cukup',
  'sogat',
  'kawet',
  'intir',
  'tapis',
  'lwang',
  'rambu',
  'rajwa',
  'waing',
  'titig',
  'tigan',
  'denya',
  'kawat',
  'kayuh',
  'cecak',
  'kulub',
  'rakud',
  'tabit',
  'sumba',
  'wekan',
  'cakup',
  'kamat',
  'tekes',
  'gayer',
  'siyub',
  'gilis',
  'buset',
  'nabok',
  'nujum',
  'kodhe',
  'kamla',
  'walan',
  'megot',
  'plaga',
  'weneh',
  'eklek',
  'dauru',
  'sugoh',
  'merek',
  'unyuk',
  'ragil',
  'doble',
  'pagak',
  'gabes',
  'jelag',
  'deloh',
  'empel',
  'ureng',
  'netah',
  'kawur',
  'dalem',
  'waung',
  'anyut',
  'mawat',
  'seyub',
  'wimba',
  'dadra',
  'bayak',
  'ungar',
  'carat',
  'mahal',
  'temen',
  'sukci',
  'ethuk',
  'nethe',
  'keple',
  'klisa',
  'mrina',
  'tabah',
  'enjot',
  'jejeg',
  'kardi',
  'jabel',
  'nyihi',
  'petek',
  'manah',
  'ancal',
  'sekek',
  'indha',
  'bilik',
  'suroh',
  'gecek',
  'duman',
  'ngong',
  'nuwun',
  'papar',
  'garoh',
  'lenga',
  'mekar',
  'nacar',
  'gurak',
  'iming',
  'kaser',
  'mulas',
  'nayom',
  'katri',
  'blayu',
  'purug',
  'wayoh',
  'saras',
  'gayes',
  'pakon',
  'endah',
  'pekik',
  'nyeng',
  'wulen',
  'lilah',
  'diweg',
  'mejan',
  'cucud',
  'raksa',
  'dlelo',
  'bekis',
  'tapen',
  'pekih',
  'raden',
  'umbak',
  'kisat',
  'kambi',
  'cumut',
  'damba',
  'iklik',
  'kroto',
  'darya',
  'narpa',
  'petar',
  'tutup',
  'kursi',
  'pipih',
  'ledre',
  'prung',
  'bakta',
  'simin',
  'esung',
  'latip',
  'pitek',
  'mucap',
  'murta',
  'lukar',
  'pojok',
  'tesir',
  'agnya',
  'racek',
  'sauga',
  'kudhi',
  'ikmat',
  'minda',
  'luwar',
  'tenta',
  'datan',
  'batha',
  'sestu',
  'rogel',
  'pokek',
  'oliya',
  'mados',
  'kiyuk',
  'sepon',
  'kurma',
  'ricik',
  'athik',
  'omong',
  'beleh',
  'menyu',
  'koyah',
  'oging',
  'tekek',
  'praen',
  'cager',
  'kemel',
  'curab',
  'sroti',
  'uthik',
  'gecel',
  'sesel',
  'gemos',
  'siyas',
  'jawoh',
  'manca',
  'harda',
  'paing',
  'santi',
  'luwer',
  'pathi',
  'laban',
  'baita',
  'jidun',
  'mrada',
  'lakya',
  'leyek',
  'punji',
  'lenge',
  'wahel',
  'ithes',
  'sukun',
  'rameh',
  'cabak',
  'miser',
  'cokor',
  'sergi',
  'beben',
  'nerpa',
  'kenci',
  'niwah',
  'wadwa',
  'mebel',
  'nitya',
  'ciyit',
  'wirid',
  'gubeg',
  'pekti',
  'empan',
  'witne',
  'pecek',
  'pelog',
  'ragen',
  'mpean',
  'ateng',
  'udhuh',
  'gulat',
  'selir',
  'mamas',
  'trusi',
  'mulur',
  'antru',
  'lebak',
  'murid',
  'pithi',
  'biler',
  'pejet',
  'blela',
  'nekuk',
  'genti',
  'peges',
  'lukat',
  'palsu',
  'nguji',
  'blong',
  'praja',
  'getem',
  'iyane',
  'tamah',
  'obrot',
  'luluh',
  'dagan',
  'kecak',
  'jayah',
  'nyaut',
  'regel',
  'tagen',
  'durma',
  'kadim',
  'pecok',
  'srana',
  'kuwah',
  'girik',
  'segel',
  'madat',
  'boros',
  'sadha',
  'tapas',
  'neple',
  'soled',
  'begar',
  'tedho',
  'bugel',
  'tarek',
  'emped',
  'gagah',
  'mucuk',
  'pirus',
  'corak',
  'wingi',
  'teteg',
  'sirna',
  'wogan',
  'kobra',
  'balig',
  'golok',
  'bestu',
  'jaksa',
  'ginda',
  'nyama',
  'muput',
  'nlole',
  'pekul',
  'nusul',
  'nimba',
  'waleh',
  'ninda',
  'etheh',
  'mulek',
  'durna',
  'tarip',
  'nrami',
  'jibus',
  'kring',
  'enyek',
  'sutri',
  'sotya',
  'perep',
  'patek',
  'petik',
  'terik',
  'potha',
  'ngusi',
  'wredu',
  'sawur',
  'ngain',
  'deles',
  'neteh',
  'celub',
  'amrat',
  'asrak',
  'leson',
  'kluyu',
  'sanga',
  'imbir',
  'kerek',
  'barah',
  'kambe',
  'mesit',
  'gubab',
  'raras',
  'mayit',
  'antup',
  'jarem',
  'nenun',
  'griya',
  'pacir',
  'sepel',
  'katon',
  'petor',
  'antun',
  'lalai',
  'majeg',
  'empis',
  'sobek',
  'becok',
  'sasih',
  'wungu',
  'jilid',
  'galok',
  'rojoh',
  'dukul',
  'borot',
  'milai',
  'gedhe',
  'pager',
  'nales',
  'ririh',
  'dimik',
  'jokal',
  'srota',
  'bubul',
  'sereg',
  'simah',
  'priti',
  'nedha',
  'aning',
  'lodan',
  'entuk',
  'jilat',
  'slaka',
  'melem',
  'perak',
  'cupak',
  'matag',
  'jobar',
  'tarok',
  'gulud',
  'manub',
  'sradi',
  'lipet',
  'jodho',
  'gebeg',
  'dados',
  'karam',
  'blelu',
  'tadha',
  'bujad',
  'malad',
  'guder',
  'bahar',
  'beres',
  'entos',
  'ketan',
  'diyat',
  'ublek',
  'saton',
  'jojog',
  'rukuh',
  'glewo',
  'kicih',
  'celor',
  'layah',
  'kebuk',
  'depok',
  'deyan',
  'kalpa',
  'jarwi',
  'mecel',
  'geges',
  'kukuk',
  'kerep',
  'kedad',
  'wawah',
  'keret',
  'leles',
  'setor',
  'mring',
  'telih',
  'kenek',
  'empol',
  'momol',
  'kumpe',
  'gumul',
  'udhak',
  'pasak',
  'entih',
  'jirim',
  'yatna',
  'nigas',
  'slepi',
  'wayon',
  'boyak',
  'balen',
  'deblo',
  'manon',
  'mikul',
  'ninis',
  'kokap',
  'dheos',
  'curat',
  'nusun',
  'under',
  'rajut',
  'lakwa',
  'metak',
  'pitik',
  'tereg',
  'posit',
  'pelas',
  'pathu',
  'waela',
  'kinon',
  'magak',
  'payus',
  'mirsa',
  'bucal',
  'lupit',
  'patra',
  'matuh',
  'mbari',
  'usreg',
  'ikrar',
  'srama',
  'larah',
  'saleh',
  'merga',
  'corah',
  'recek',
  'mopok',
  'mesih',
  'wandu',
  'maelu',
  'linud',
  'cowet',
  'kecoh',
  'samar',
  'umung',
  'germa',
  'kidib',
  'puyut',
  'manih',
  'sirap',
  'sarab',
  'pling',
  'sekul',
  'taoco',
  'besuk',
  'nelag',
  'menco',
  'iyeki',
  'kepuh',
  'sande',
  'rereb',
  'ambok',
  'mibah',
  'anguk',
  'gopes',
  'peluk',
  'kanan',
  'kilak',
  'agung',
  'ciker',
  'cocol',
  'mamin',
  'asing',
  'ageng',
  'tatab',
  'gabug',
  'nonok',
  'lireh',
  'teruh',
  'masek',
  'jowar',
  'nggon',
  'tarib',
  'delir',
  'kebet',
  'gegem',
  'merot',
  'siwak',
  'ambil',
  'nraya',
  'tujah',
  'blaos',
  'wasis',
  'lupta',
  'jabon',
  'epluk',
  'enjep',
  'berji',
  'panas',
  'prase',
  'maled',
  'jolok',
  'bekne',
  'kasap',
  'merna',
  'aring',
  'cebur',
  'jalan',
  'turas',
  'duhet',
  'cibuk',
  'wiruh',
  'mosil',
  'cawet',
  'jekut',
  'othot',
  'peron',
  'kados',
  'gurem',
  'lerah',
  'rejak',
  'ngaco',
  'aseli',
  'jagan',
  'kuras',
  'temah',
  'megek',
  'punah',
  'reyon',
  'catra',
  'selar',
  'ampek',
  'tirah',
  'mapal',
  'mayuk',
  'kosya',
  'enget',
  'gurit',
  'rapuh',
  'hawin',
  'genep',
  'patar',
  'poran',
  'liyak',
  'lambe',
  'gulak',
  'gupur',
  'kewek',
  'gisus',
  'pirma',
  'kilan',
  'beran',
  'pocot',
  'tuduh',
  'wasir',
  'plasa',
  'kolur',
  'kebon',
  'seple',
  'kelok',
  'paken',
  'pikun',
  'dhepo',
  'pintu',
  'modod',
  'hawan',
  'bateh',
  'sahab',
  'topen',
  'jenek',
  'kajar',
  'adhah',
  'regag',
  'mulih',
  'plaur',
  'hiwag',
  'kasok',
  'subal',
  'rampe',
  'metar',
  'munji',
  'watak',
  'petho',
  'kejer',
  'batan',
  'dhaup',
  'ragad',
  'egong',
  'ramuh',
  'mekul',
  'midhe',
  'tayum',
  'kumud',
  'gepit',
  'tebak',
  'kubek',
  'wiguh',
  'wareg',
  'lapur',
  'inguk',
  'puges',
  'clulu',
  'nayub',
  'liput',
  'dwaya',
  'akral',
  'kemah',
  'kotol',
  'cawuh',
  'miwir',
  'toleh',
  'cugar',
  'prasu',
  'wredi',
  'kenal',
  'panja',
  'lawan',
  'warak',
  'dheye',
  'komis',
  'creme',
  'bekta',
  'cicir',
  'kenur',
  'ambak',
  'royom',
  'mredu',
  'enceh',
  'busuk',
  'byaya',
  'latak',
  'pijet',
  'wadad',
  'thung',
  'regem',
  'gipih',
  'pupus',
  'udama',
  'tholo',
  'konta',
  'pokik',
  'jakat',
  'mapan',
  'bogya',
  'kobis',
  'wedal',
  'bareh',
  'limun',
  'matoh',
  'gayem',
  'wanci',
  'mekao',
  'notol',
  'natal',
  'sunya',
  'ngibu',
  'prama',
  'susuk',
  'matah',
  'nyaur',
  'terob',
  'trasa',
  'gunem',
  'iyong',
  'sulbi',
  'nacab',
  'lumud',
  'prosa',
  'butuh',
  'benik',
  'gujig',
  'liger',
  'kudur',
  'ruwag',
  'srawe',
  'wyaen',
  'encit',
  'rukon',
  'dhage',
  'jarot',
  'semir',
  'mehna',
  'kerap',
  'sekeb',
  'guwab',
  'utang',
  'cokek',
  'kuper',
  'embuh',
  'nisik',
  'maiti',
  'bibal',
  'pagas',
  'tuten',
  'piyas',
  'arang',
  'dhele',
  'turne',
  'botoh',
  'jepet',
  'mapas',
  'kajat',
  'gorek',
  'kunci',
  'renes',
  'bebet',
  'siwut',
  'selen',
  'kirta',
  'kulib',
  'bagol',
  'tuwah',
  'gejes',
  'sebar',
  'wilma',
  'angga',
  'warya',
  'gelut',
  'obyor',
  'tetep',
  'katir',
  'mesri',
  'wuruk',
  'lurah',
  'rakta',
  'bratu',
  'laler',
  'bulus',
  'lirih',
  'konca',
  'mular',
  'ngela',
  'jejek',
  'injit',
  'ruwat',
  'geyor',
  'bekik',
  'nyoro',
  'pusek',
  'pupug',
  'nerat',
  'jarae',
  'neser',
  'wejak',
  'pehan',
  'gayar',
  'kucek',
  'jujur',
  'lurus',
  'jerum',
  'mirak',
  'pedah',
  'nagur',
  'ramat',
  'yusya',
  'cameh',
  'sunar',
  'barih',
  'gerak',
  'cocok',
  'nolak',
  'ongot',
  'mulya',
  'anget',
  'riguh',
  'sarya',
  'nyiwi',
  'kedut',
  'margi',
  'pisan',
  'mengi',
  'nyela',
  'padam',
  'wajan',
  'ulama',
  'sulih',
  'kuwut',
  'piala',
  'bawuk',
  'ilang',
  'sowak',
  'wadal',
  'baken',
  'kruya',
  'oncog',
  'corok',
  'lanja',
  'rimah',
  'anger',
  'elong',
  'rebet',
  'guhya',
  'sobat',
  'salak',
  'rukti',
  'kempu',
  'ibing',
  'abyag',
  'tukar',
  'kukap',
  'purwa',
  'lawet',
  'angas',
  'ceper',
  'lawed',
  'setya',
  'darba',
  'padon',
  'prema',
  'gugon',
  'gerat',
  'konus',
  'angap',
  'kalir',
  'wwara',
  'leyep',
  'karat',
  'toler',
  'telak',
  'dremi',
  'renep',
  'sepah',
  'rurek',
  'riris',
  'rapet',
  'rawat',
  'senes',
  'toyan',
  'lasun',
  'gereh',
  'heret',
  'silih',
  'ujung',
  'garut',
  'malir',
  'leket',
  'nyeta',
  'anyeb',
  'dumuk',
  'kepah',
  'zabur',
  'obral',
  'turuh',
  'larek',
  'halat',
  'angsa',
  'luwih',
  'prana',
  'neyok',
  'mlela',
  'lokak',
  'kebut',
  'cabya',
  'turah',
  'suwek',
  'riben',
  'betah',
  'rocoh',
  'cecek',
  'teteh',
  'dhuma',
  'totor',
  'lamuk',
  'nocog',
  'angah',
  'udhun',
  'dhong',
  'ekroh',
  'banon',
  'cekas',
  'pugut',
  'peksi',
  'jejak',
  'taruh',
  'terni',
  'renik',
  'ojrat',
  'gumer',
  'burik',
  'gwang',
  'bejos',
  'adhem',
  'ladya',
  'resek',
  'nilib',
  'lekas',
  'mlebu',
  'megok',
  'tabet',
  'riyon',
  'nulup',
  'sigak',
  'gelak',
  'seleg',
  'hunur',
  'jeheh',
  'lulub',
  'slera',
  'jimpe',
  'salok',
  'ngiwa',
  'thika',
  'pamah',
  'murak',
  'curug',
  'beser',
  'kasdu',
  'geder',
  'kiwal',
  'jened',
  'geget',
  'yatra',
  'gowek',
  'katak',
  'nukup',
  'kecer',
  'nyuki',
  'lugek',
  'mutah',
  'gebig',
  'ithik',
  'kebul',
  'gebis',
  'taluh',
  'jawah',
  'pidih',
  'kokar',
  'mowod',
  'lyang',
  'trami',
  'giber',
  'metel',
  'jawel',
  'langu',
  'mejak',
  'remit',
  'graja',
  'umpan',
  'rokel',
  'pulya',
  'talok',
  'melar',
  'cawar',
  'samun',
  'golek',
  'kocoh',
  'jigur',
  'waspa',
  'keluh',
  'tegin',
  'praji',
  'bisit',
  'sotho',
  'kaduk',
  'genau',
  'cibur',
  'krosi',
  'majik',
  'cuwol',
  'lemet',
  'dumeh',
  'kedek',
  'jinja',
  'idhem',
  'sabeg',
  'selur',
  'kanil',
  'limar',
  'limus',
  'lojeh',
  'ormat',
  'pakra',
  'warta',
  'setik',
  'sadho',
  'gobis',
  'blaer',
  'pohot',
  'melut',
  'enyat',
  'wangi',
  'kacur',
  'leboh',
  'badir',
  'tujon',
  'kures',
  'bakti',
  'ngreh',
  'parol',
  'sreda',
  'lemak',
  'empok',
  'puser',
  'lamak',
  'jaman',
  'benet',
  'paksa',
  'keswa',
  'gesau',
  'krepu',
  'onyok',
  'jimuk',
  'pilar',
  'calik',
  'ruwah',
  'ambal',
  'begja',
  'ramya',
  'ngger',
  'tegil',
  'puput',
  'jurag',
  'gaeng',
  'kelon',
  'labuh',
  'anyul',
  'eblak',
  'lagep',
  'royot',
  'pundi',
  'anter',
  'lamar',
  'gojeh',
  'cegot',
  'pesek',
  'pocok',
  'praos',
  'sepak',
  'malak',
  'rogok',
  'kiper',
  'tetag',
  'kenop',
  'saler',
  'melak',
  'pugas',
  'buwis',
  'buken',
  'meron',
  'ngore',
  'sinar',
  'lante',
  'nguja',
  'nirat',
  'sakit',
  'mobet',
  'getun',
  'nitik',
  'meneb',
  'lilir',
  'wirun',
  'ditya',
  'pencu',
  'wuwus',
  'sayah',
  'kukum',
  'sudhi',
  'satya',
  'pekak',
  'kakak',
  'ranuh',
  'buron',
  'ngere',
  'cipta',
  'kapri',
  'blaki',
  'kopek',
  'mucak',
  'jitun',
  'jenat',
  'kagol',
  'batal',
  'sikat',
  'hadha',
  'weweg',
  'koswa',
  'gemet',
  'goget',
  'teken',
  'antem',
  'carub',
  'lebar',
  'kahwa',
  'geras',
  'ampok',
  'prail',
  'megin',
  'mumuk',
  'ungel',
  'nopal',
  'gelas',
  'remot',
  'sarju',
  'sibar',
  'nesek',
  'cakul',
  'wisan',
  'galuh',
  'cerak',
  'mesat',
  'botor',
  'lebot',
  'jemeh',
  'thing',
  'sarip',
  'kawil',
  'kawan',
  'jelek',
  'werak',
  'morah',
  'emban',
  'lawer',
  'warsi',
  'kimat',
  'beksa',
  'kelam',
  'lebur',
  'bacok',
  'kasep',
  'wanwa',
  'males',
  'latek',
  'nirok',
  'kopoh',
  'jwala',
  'gayel',
  'tabik',
  'johar',
  'ampas',
  'juwis',
  'tepat',
  'lereg',
  'dodor',
  'bewes',
  'klawu',
  'benah',
  'gerap',
  'kusan',
  'muded',
  'puguh',
  'jeneh',
  'polok',
  'turuk',
  'cobak',
  'wektu',
  'gerpu',
  'ribug',
  'salah',
  'pegos',
  'megos',
  'engos',
  'bakuh',
  'ceguk',
  'magih',
  'togke',
  'ngowe',
  'guguh',
  'encer',
  'ngili',
  'luruh',
  'lerem',
  'raina',
  'pirak',
  'nyape',
  'rolis',
  'suwun',
  'jiput',
  'wuntu',
  'sulek',
  'ubral',
  'ungal',
  'dilik',
  'nylao',
  'jebur',
  'tinut',
  'sugri',
  'pipik',
  'magal',
  'karek',
  'koras',
  'muwer',
  'wisel',
  'jriji',
  'nosog',
  'luber',
  'anjut',
  'belis',
  'damen',
  'nayut',
  'peleh',
  'nyeok',
  'sahya',
  'sosog',
  'tikel',
  'unyur',
  'katub',
  'magro',
  'berit',
  'terep',
  'kalas',
  'angum',
  'napal',
  'miyur',
  'emper',
  'kresa',
  'ontel',
  'tenun',
  'ruwit',
  'ropel',
  'bales',
  'kecek',
  'sekon',
  'kuris',
  'kuldi',
  'sejid',
  'greha',
  'edhul',
  'dalir',
  'murba',
  'tiket',
  'dhine',
  'wekel',
  'denta',
  'mineb',
  'murud',
  'suwul',
  'beyes',
  'lerod',
  'remah',
  'serap',
  'nyupu',
  'kodhi',
  'drana',
  'giras',
  'nutup',
  'tarki',
  'gigis',
  'kleca',
  'nirna',
  'tatwa',
  'susun',
  'umbut',
  'ngene',
  'anjer',
  'tanji',
  'gilir',
  'janur',
  'kakap',
  'duhka',
  'jemok',
  'najam',
  'suwan',
  'ketok',
  'bathu',
  'nyupe',
  'liman',
  'tarap',
  'eblag',
  'nadya',
  'embil',
  'maris',
  'pesta',
  'ubeng',
  'luwak',
  'nelas',
  'kesat',
  'becik',
  'sruba',
  'keser',
  'jaran',
  'kinah',
  'kiter',
  'pejah',
  'keben',
  'cerik',
  'sapeh',
  'iyang',
  'tompo',
  'santa',
  'tebes',
  'lumra',
  'kabeh',
  'korok',
  'nugel',
  'bigar',
  'besut',
  'darbe',
  'kasab',
  'rijig',
  'yakna',
  'carak',
  'jegos',
  'bejan',
  'wetah',
  'ambet',
  'wrata',
  'biyek',
  'demek',
  'sorog',
  'rodra',
  'karah',
  'lampi',
  'ancep',
  'cekap',
  'globe',
  'nayum',
  'kakus',
  'mambu',
  'doran',
  'kapal',
  'maesa',
  'cioko',
  'kobot',
  'katis',
  'winga',
  'galap',
  'lowek',
  'lekek',
  'monjo',
  'ksama',
  'curak',
  'kahar',
  'sugeh',
  'priye',
  'jugah',
  'syang',
  'balap',
  'supek',
  'ngula',
  'nabag',
  'kubuk',
  'mugag',
  'kedhe',
  'mepet',
  'ngedu',
  'pawar',
  'betek',
  'picak',
  'gupis',
  'kermi',
  'pures',
  'idham',
  'sawah',
  'pokah',
  'leseh',
  'waril',
  'kacuk',
  'gerek',
  'april',
  'bibek',
  'ogrok',
  'amput',
  'pribu',
  'tarak',
  'curah',
  'werga',
  'sisil',
  'pethi',
  'gimir',
  'ompak',
  'sugal',
  'nesep',
  'lamun',
  'keler',
  'pakta',
  'nogog',
  'rekta',
  'nusus',
  'argya',
  'rajag',
  'cemol',
  'keker',
  'iplik',
  'dulit',
  'yeksa',
  'cekit',
  'allah',
  'ebang',
  'kemat',
  'ganti',
  'bayor',
  'endat',
  'cewok',
  'putri',
  'purik',
  'munya',
  'remak',
  'terak',
  'pupak',
  'laras',
  'cekot',
  'jaruh',
  'lerep',
  'kojor',
  'widhe',
  'ancan',
  'misri',
  'gurdi',
  'misan',
  'kober',
  'lemar',
  'ragem',
  'gring',
  'mipih',
  'moyar',
  'citho',
  'liyer',
  'suruh',
  'regol',
  'kuwur',
  'sagon',
  'becus',
  'kowek',
  'irung',
  'sloka',
  'rawoh',
  'mekeh',
  'kipas',
  'legoh',
  'kenep',
  'suweg',
  'nilad',
  'kliru',
  'ladha',
  'nyabe',
  'jejet',
  'kenup',
  'maweh',
  'tauco',
  'wagya',
  'untar',
  'diyon',
  'pleng',
  'gujeg',
  'suwir',
  'camik',
  'macem',
  'gujer',
  'nilep',
  'lapan',
  'lewah',
  'layar',
  'metek',
  'midak',
  'opyok',
  'pelem',
  'colek',
  'akasa',
  'rayuk',
  'undha',
  'talun',
  'borek',
  'atiki',
  'wilut',
  'wajib',
  'bamba',
  'koyok',
  'tolor',
  'muter',
  'likir',
  'curek',
  'karas',
  'tugar',
  'jarat',
  'kalus',
  'garep',
  'tatas',
  'kampi',
  'gawir',
  'gimin',
  'sepur',
  'rejek',
  'pahal',
  'pyung',
  'milut',
  'kasor',
  'pring',
  'pakis',
  'jurak',
  'koyek',
  'cacah',
  'ampuh',
  'musig',
  'gejil',
  'mupur',
  'sabun',
  'namar',
  'mekek',
  'topoh',
  'jilak',
  'ilung',
  'diwya',
  'nebih',
  'wutuh',
  'padya',
  'nasak',
  'ceple',
  'arean',
  'odhol',
  'batin',
  'kelik',
  'gatos',
  'ahyas',
  'gebog',
  'dheng',
  'imboh',
  'dikep',
  'simpe',
  'domel',
  'enteb',
  'gayut',
  'cukil',
  'nyepi',
  'antri',
  'belak',
  'sreba',
  'lagan',
  'lerek',
  'caket',
  'tapir',
  'malar',
  'saman',
  'riyuk',
  'kekeb',
  'bubut',
  'kiyam',
  'gayuh',
  'garis',
  'bagas',
  'landa',
  'anjal',
  'wagus',
  'embed',
  'rawuk',
  'pupuh',
  'umpah',
  'besen',
  'titik',
  'sukra',
  'cacar',
  'pacul',
  'aseng',
  'gelok',
  'angin',
  'rucah',
  'bulek',
  'hawer',
  'incup',
  'jedok',
  'dheto',
  'butoh',
  'muncu',
  'embar',
  'ingak',
  'rapen',
  'etong',
  'teter',
  'plala',
  'sepat',
  'ragah',
  'jikut',
  'rebat',
  'locok',
  'mubah',
  'keleb',
  'cepor',
  'muris',
  'kusir',
  'genah',
  'panen',
  'pepeh',
  'dagda',
  'gebag',
  'anyes',
  'cepit',
  'gagap',
  'ingon',
  'tumat',
  'melek',
  'lohor',
  'tuwek',
  'palen',
  'lapis',
  'puter',
  'cemet',
  'locan',
  'kembu',
  'dodod',
  'talad',
  'dhauk',
  'natar',
  'selap',
  'ukara',
  'lopor',
  'pelag',
  'kereg',
  'kelep',
  'pacar',
  'sinep',
  'omber',
  'buyuk',
  'wulon',
  'besot',
  'cempe',
  'kenet',
  'uplik',
  'walat',
  'matri',
  'gapuk',
  'mawon',
  'wijik',
  'sikut',
  'emong',
  'gayor',
  'kirim',
  'setri',
  'kudhu',
  'derep',
  'lasar',
  'nokil',
  'berag',
  'mlere',
  'takut',
  'wegig',
  'tumpi',
  'miwal',
  'engis',
  'maruk',
  'bewok',
  'waged',
  'jamba',
  'culat',
  'udhel',
  'rawek',
  'ketug',
  'megep',
  'mruga',
  'ninga',
  'tulup',
  'bilai',
  'ngupa',
  'anglo',
  'sigit',
  'jabar',
  'dapak',
  'molek',
  'waras',
  'tulek',
  'turon',
  'ligar',
  'bilis',
  'iwung',
  'piser',
  'uwang',
  'prigi',
  'kored',
  'klayu',
  'nyewa',
  'gupon',
  'jeron',
  'gorok',
  'truwu',
  'incer',
  'kolor',
  'muber',
  'uraka',
  'ngoko',
  'neset',
  'acara',
  'serut',
  'lilis',
  'cawik',
  'terna',
  'samak',
  'supak',
  'simit',
  'kuwek',
  'rabek',
  'lapak',
  'wijuk',
  'tapek',
  'cegur',
  'unjal',
  'bekus',
  'kanjo',
  'ayeng',
  'welas',
  'setun',
  'dolan',
  'pelen',
  'yiyid',
  'jidik',
  'raswa',
  'sotir',
  'angop',
  'cekre',
  'miyos',
  'jadhe',
  'palwa',
  'kopik',
  'anten',
  'niyup',
  'wegah',
  'tubug',
  'beras',
  'tuhun',
  'mompa',
  'serek',
  'dhoko',
  'sepen',
  'walah',
  'kaceb',
  'impun',
  'curna',
  'sarug',
  'ragab',
  'wakaf',
  'lobok',
  'naros',
  'nisil',
  'doyan',
  'mulai',
  'cekli',
  'porod',
  'bobab',
  'antan',
  'tumok',
  'gadhe',
  'timen',
  'kraos',
  'nebit',
  'sebok',
  'aneka',
  'jajan',
  'tedhi',
  'rento',
  'getak',
  'gerwa',
  'pileg',
  'niyah',
  'cocak',
  'zakat',
  'jubar',
  'ruweh',
  'dheku',
  'isbat',
  'jubin',
  'jajag',
  'sumur',
  'cakil',
  'lyong',
  'nebas',
  'sawat',
  'wigna',
  'sapah',
  'kuwat',
  'pikne',
  'debes',
  'prang',
  'krepo',
  'grisi',
  'paruh',
  'umpal',
  'giwar',
  'solor',
  'sugya',
  'susug',
  'wenes',
  'cicip',
  'lafal',
  'dhari',
  'wajik',
  'until',
  'gewol',
  'piles',
  'asana',
  'natah',
  'pamor',
  'slepa',
  'biyuk',
  'cahak',
  'mrusa',
  'kawin',
  'eling',
  'rajug',
  'dluya',
  'danta',
  'slepe',
  'tantu',
  'ebles',
  'matun',
  'tombe',
  'luwat',
  'enjuh',
  'puhan',
  'gobog',
  'embag',
  'jawil',
  'embal',
  'anjur',
  'kadas',
  'kusut',
  'leler',
  'ramut',
  'lesan',
  'ebreh',
  'cleng',
  'rekat',
  'tesih',
  'wruju',
  'tuman',
  'umrik',
  'uncek',
  'antal',
  'magan',
  'kasar',
  'kroco',
  'sosis',
  'winot',
  'epyek',
  'ranji',
  'jebot',
  'gemah',
  'cuwut',
  'entut',
  'andul',
  'piyul',
  'kenol',
  'belet',
  'nasib',
  'babah',
  'cabuk',
  'nyiru',
  'wlaka',
  'celer',
  'bakda',
  'glugu',
  'kelem',
  'sanak',
  'kapwa',
  'slang',
  'rumah',
  'biyas',
  'mahas',
  'pulon',
  'ngeng',
  'thila',
  'kamot',
  'macar',
  'sasab',
  'obeng',
  'pakeh',
  'kogel',
  'kakah',
  'laden',
  'jugug',
  'mulus',
  'mihak',
  'kambu',
  'semet',
  'cucal',
  'jelir',
  'awung',
  'tibra',
  'gibas',
  'nikel',
  'peyok',
  'gawat',
  'gajul',
  'lelur',
  'ngeme',
  'ering',
  'setel',
  'lawak',
  'dower',
  'cekeh',
  'teleb',
  'talib',
  'babon',
  'misih',
  'selut',
  'waktu',
  'rereh',
  'buntu',
  'lisus',
  'micis',
  'kethu',
  'kobak',
  'putra',
  'kapak',
  'boreg',
  'barep',
  'serta',
  'mungu',
  'gamil',
  'obyok',
  'semil',
  'mahar',
  'lupai',
  'morot',
  'lorog',
  'gulma',
  'selip',
  'sunah',
  'rocok',
  'enyep',
  'empek',
  'tegal',
  'dhatu',
  'pideh',
  'kruma',
  'nahwu',
  'gasak',
  'jejer',
  'kebar',
  'retos',
  'jelih',
  'malih',
  'kitab',
  'rijol',
  'crema',
  'taros',
  'isika',
  'mojar',
  'libar',
  'puwer',
  'laduk',
  'garit',
  'adama',
  'wanah',
  'karem',
  'nilak',
  'kakal',
  'babal',
  'ciloh',
  'othek',
  'delap',
  'parab',
  'kayun',
  'apura',
  'pinet',
  'mirut',
  'etang',
  'kesdu',
  'tagon',
  'tewer',
  'kayal',
  'lasir',
  'kedul',
  'rosan',
  'weleg',
  'enung',
  'kreng',
  'lelep',
  'galah',
  'rabuk',
  'macak',
  'kerga',
  'asrep',
  'genen',
  'siyal',
  'muleh',
  'dhang',
  'gurna',
  'lekok',
  'wardi',
  'adara',
  'iyeng',
  'ranti',
  'suket',
  'garek',
  'driya',
  'pucat',
  'pecot',
  'pepak',
  'naruh',
  'sikik',
  'tajug',
  'udhar',
  'encok',
  'gagar',
  'salju',
  'prige',
  'kucem',
  'gulek',
  'gejog',
  'pukah',
  'busek',
  'gemel',
  'maugi',
  'genan',
  'norap',
  'sodok',
  'muluk',
  'pepes',
  'breng',
  'estha',
  'gurda',
  'glura',
  'tobit',
  'rapeh',
  'semar',
  'nener',
  'gecot',
  'pekah',
  'ambat',
  'karan',
  'jamas',
  'mosik',
  'nulis',
  'caluk',
  'nacad',
  'ikral',
  'gilut',
  'bunar',
  'gusah',
  'kumat',
  'manol',
  'idhop',
  'ricih',
  'mlite',
  'engga',
  'korah',
  'jogan',
  'kinen',
  'sileh',
  'sampe',
  'tembe',
  'nepsu',
  'mrabu',
  'taler',
  'raseh',
  'presa',
  'wiyat',
  'nyles',
  'racut',
  'necep',
  'jumuk',
  'islam',
  'cikar',
  'lanjo',
  'palal',
  'wiyah',
  'tegen',
  'babas',
  'semak',
  'jeren',
  'kutug',
  'ubres',
  'seleh',
  'dhepa',
  'nohan',
  'nyiwo',
  'namur',
  'gusar',
  'kutut',
  'tipus',
  'jepek',
  'midid',
  'kisik',
  'sasra',
  'cepol',
  'piket',
  'rumbu',
  'bikin',
  'sayom',
  'nyatu',
  'risig',
  'selan',
  'bligo',
  'nened',
  'uceng',
  'angus',
  'kenit',
  'manus',
  'siruk',
  'bathi',
  'leyod',
  'abyak',
  'peres',
  'remen',
  'kemen',
  'bakul',
  'obrus',
  'cewet',
  'omben',
  'basin',
  'sahal',
  'jogah',
  'gares',
  'lakar',
  'pacas',
  'asari',
  'ronje',
  'guyon',
  'bawat',
  'pakem',
  'kawir',
  'pagol',
  'wujil',
  'goman',
  'milih',
  'ngowi',
  'muhal',
  'kicer',
  'pekok',
  'tenja',
  'pogot',
  'wuluh',
  'gajeg',
  'selam',
  'kalok',
  'aglar',
  'jampi',
  'riwed',
  'mleni',
  'dipun',
  'kelet',
  'barud',
  'gimik',
  'obyag',
  'taruk',
  'geman',
  'sijok',
  'empir',
  'kawes',
  'pakah',
  'punas',
  'ilham',
  'cukul',
  'jepat',
  'bayah',
  'akyan',
  'leswa',
  'jekat',
  'tekak',
  'kemis',
  'cilim',
  'rekep',
  'wical',
  'rayat',
  'tumus',
  'drawa',
  'seyeg',
  'dimon',
  'kelah',
  'altar',
  'rapal',
  'enthu',
  'kokol',
  'bereg',
  'kutah',
  'sreng',
  'kadar',
  'anjog',
  'pecis',
  'putih',
  'saput',
  'lilih',
  'suduk',
  'mimir',
  'banyu',
  'kacel',
  'mecut',
  'siset',
  'ladan',
  'pyayi',
  'cumpi',
  'upeti',
  'taleh',
  'kosik',
  'lanji',
  'maman',
  'kebur',
  'jekek',
  'gelit',
  'detya',
  'tetel',
  'mlucu',
  'adhul',
  'rapek',
  'jimak',
  'karni',
  'begog',
  'sesep',
  'caing',
  'masin',
  'rawan',
  'jenak',
  'katok',
  'rejog',
  'empen',
  'kupik',
  'paman',
  'mathi',
  'peang',
  'mocok',
  'potho',
  'nurus',
  'anduk',
  'dimek',
  'imbar',
  'juwek',
  'injik',
  'pupur',
  'cucur',
  'maleh',
  'sepan',
  'cicil',
  'sekal',
  'ompot',
  'klasa',
  'lahya',
  'taman',
  'canik',
  'nabur',
  'mosok',
  'bukuh',
  'tuwan',
  'nerok',
  'sipwa',
  'wadya',
  'janah',
  'wijil',
  'namek',
  'kebes',
  'mejek',
  'kosek',
  'lebda',
  'aking',
  'jugul',
  'jibah',
  'murca',
  'milik',
  'egung',
  'jiret',
  'muluh',
  'supir',
  'parud',
  'wewes',
  'leder',
  'conti',
  'suwab',
  'boral',
  'jemut',
  'wibuh',
  'kenap',
  'sruwa',
  'jitah',
  'pules',
  'ngana',
  'rebab',
  'suwot',
  'sesah',
  'pidak',
  'girap',
  'cimik',
  'sebal',
  'wados',
  'macal',
  'buwat',
  'cuwil',
  'elung',
  'yetos',
  'cicut',
  'babut',
  'medhi',
  'pelan',
  'cinta',
  'tolak',
  'dedah',
  'mawoh',
  'maisa',
  'sayub',
  'lobak',
  'runah',
  'bogem',
  'akrab',
  'ampah',
  'tigas',
  'wuled',
  'giyuh',
  'jawal',
  'jepen',
  'ngasa',
  'yutun',
  'nasar',
  'cuwer',
  'iyeku',
  'perut',
  'paeka',
  'nembo',
  'wiwit',
  'maton',
  'milar',
  'serat',
  'lekne',
  'menik',
  'bwang',
  'gasek',
  'yitma',
  'delul',
  'ngadu',
  'jujug',
  'nerak',
  'receh',
  'ombak',
  'penah',
  'entas',
  'bakal',
  'ethek',
  'wodha',
  'karuh',
  'balya',
  'mohel',
  'benjo',
  'ganol',
  'kilat',
  'suren',
  'kocap',
  'kabir',
  'maido',
  'tinda',
  'remed',
  'kosok',
  'bobok',
  'momot',
  'kasur',
  'cedan',
  'curet',
  'pisek',
  'wates',
  'andhe',
  'bangi',
  'lemud',
  'srani',
  'nedya',
  'gasik',
  'lalad',
  'nyuru',
  'jawab',
  'kolot',
  'tirep',
  'popor',
  'kepus',
  'titah',
  'neres',
  'jebus',
  'morod',
  'selup',
  'kakas',
  'baros',
  'glana',
  'sejen',
  'tuwas',
  'reyoh',
  'tapeh',
  'rimuk',
  'sewak',
  'enjut',
  'telat',
  'mipis',
  'rigel',
  'abdas',
  'rayap',
  'losor',
  'kirna',
  'tawar',
  'koreh',
  'nukma',
  'romes',
  'jigol',
  'bolot',
  'curuh',
  'gaber',
  'kasek',
  'carik',
  'paser',
  'leker',
  'ruruh',
  'nepos',
  'jalen',
  'walik',
  'jagur',
  'gatel',
  'ngeri',
  'legeg',
  'wadul',
  'katur',
  'ampet',
  'birat',
  'edhem',
  'karun',
  'ancam',
  'nelik',
  'wengi',
  'empun',
  'graji',
  'duleg',
  'renda',
  'memes',
  'remek',
  'ampir',
  'numpu',
  'aglah',
  'kaspa',
  'melog',
  'ribut',
  'saeng',
  'ripuh',
  'uwung',
  'udung',
  'takul',
  'eklas',
  'dhaon',
  'lilin',
  'pepet',
  'molai',
  'pinta',
  'bubus',
  'kokok',
  'glojo',
  'becah',
  'siyen',
  'mande',
  'melih',
  'gadul',
  'malam',
  'daoke',
  'leher',
  'manga',
  'jatuh',
  'pindi',
  'pista',
  'ngasu',
  'pirit',
  'liyat',
  'creng',
  'abadi',
  'kersa',
  'kelir',
  'kelar',
  'damel',
  'penuh',
  'siwir',
  'pecal',
  'jraga',
  'titip',
  'perih',
  'kaken',
  'batur',
  'edhos',
  'wahya',
  'amben',
  'nitra',
  'kureb',
  'ngaen',
  'rajeh',
  'bapra',
  'cacad',
  'jihwa',
  'mujah',
  'tonil',
  'blaur',
  'ompod',
  'meluk',
  'yawat',
  'tulus',
  'tiyup',
  'surem',
  'tewel',
  'bintu',
  'kanya',
  'getar',
  'madya',
  'yasin',
  'sopan',
  'melad',
  'takek',
  'jiwit',
  'wurah',
  'genuk',
  'jeksa',
  'ampal',
  'udhet',
  'beset',
  'madon',
  'rabat',
  'lurik',
  'jugag',
  'godha',
  'genet',
  'talen',
  'narok',
  'kwawa',
  'nyate',
  'poyan',
  'balon',
  'kurah',
  'senen',
  'kapul',
  'miris',
  'darak',
  'lemah',
  'kucir',
  'camah',
  'tasan',
  'nular',
  'manak',
  'driji',
  'gerit',
  'gepak',
  'romah',
  'takok',
  'waton',
  'ontog',
  'gaben',
  'secok',
  'nelih',
  'koten',
  'babak',
  'licit',
  'ujani',
  'tatha',
  'piksa',
  'jeluk',
  'gegel',
  'arjwa',
  'utami',
  'meyen',
  'sarat',
  'anyak',
  'pereh',
  'ubreg',
  'gegek',
  'kabur',
  'porok',
  'mindi',
  'cotha',
  'sipik',
  'legon',
  'ngipi',
  'siweg',
  'gurma',
  'umyek',
  'patri',
  'radin',
  'rayud',
  'kayul',
  'prelu',
  'pilis',
  'magel',
  'boten',
  'lepeh',
  'kurup',
  'rekal',
  'kenas',
  'besar',
  'lenah',
  'giles',
  'galak',
  'glang',
  'ompok',
  'sogel',
  'berud',
  'kerat',
  'legan',
  'jedhe',
  'engko',
  'pelus',
  'leser',
  'jegan',
  'jirih',
  'tabeh',
  'wuduk',
  'peran',
  'polet',
  'deleg',
  'dedeg',
  'buwuh',
  'lidok',
  'ksana',
  'lomoh',
  'buwah',
  'liren',
  'garet',
  'leyok',
  'beler',
  'jagad',
  'reren',
  'dinar',
  'untek',
  'koyuk',
  'datuk',
  'kemil',
  'sweda',
  'cerem',
  'keron',
  'kabuk',
  'dokan',
  'neptu',
  'bayem',
  'bithi',
  'pilet',
  'tobal',
  'reken',
  'rubes',
  'babat',
  'weleh',
  'dhaos',
  'ngare',
  'nocoh',
  'reyab',
  'gubah',
  'nglir',
  'bibar',
  'bewak',
  'jaweh',
  'rarah',
  'carma',
  'juwot',
  'antob',
  'incim',
  'waksa',
  'rahat',
  'belah',
  'dolop',
  'bebas',
  'graut',
  'urung',
  'paseh',
  'cebol',
  'eseng',
  'conto',
  'layak',
  'susra',
  'tembo',
  'ropal',
  'crobo',
  'ruksa',
  'pakel',
  'manco',
  'gubar',
  'sayak',
  'necek',
  'iking',
  'niaya',
  'cikat',
  'praya',
  'umbul',
  'karga',
  'geses',
  'jakim',
  'murit',
  'bayas',
  'jimpo',
  'mojed',
  'coger',
  'samya',
  'ratap',
  'rapel',
  'nguli',
  'impen',
  'cukit',
  'byuha',
  'tepas',
  'welar',
  'calak',
  'setup',
  'angok',
  'riyak',
  'wajah',
  'mopol',
  'wedos',
  'gepuk',
  'bibas',
  'sebah',
  'plana',
  'leter',
  'dhipo',
  'cenol',
  'oncom',
  'kuswa',
  'nebus',
  'lasah',
  'wanda',
  'mecat',
  'watir',
  'cewek',
  'peleg',
  'toret',
  'oncek',
  'rabit',
  'ratum',
  'ablak',
  'trawu',
  'kraek',
  'tedha',
  'patha',
  'gogor',
  'godog',
  'maron',
  'abjad',
  'nolih',
  'debog',
  'kremi',
  'genje',
  'weweh',
  'wwalu',
  'keton',
  'meses',
  'kogug',
  'koper',
  'bobat',
  'banen',
  'endem',
  'culuh',
  'tasik',
  'saksi',
  'plero',
  'apuwa',
  'purut',
  'janji',
  'tapan',
  'nyeno',
  'upadi',
  'senor',
  'kethi',
  'lelah',
  'nebda',
  'kerem',
  'tiker',
  'kepis',
  'krete',
  'obrog',
  'riyek',
  'pokol',
  'pekoh',
  'thele',
  'lemeh',
  'magas',
  'lawon',
  'tarab',
  'jewer',
  'tegor',
  'swari',
  'dumil',
  'siluk',
  'senar',
  'copet',
  'kinca',
  'tomat',
  'bujel',
  'lagek',
  'jatoh',
  'intip',
  'werna',
  'kolog',
  'gewar',
  'ugung',
  'larud',
  'semut',
  'kecap',
  'maski',
  'pokak',
  'dores',
  'dilem',
  'celen',
  'adheg',
  'penat',
  'minat',
  'siyam',
  'podhi',
  'mokta',
  'cukeh',
  'wayah',
  'sokur',
  'ebrok',
  'sarik',
  'gotra',
  'dwara',
  'kadut',
  'edhag',
  'ambar',
  'umpon',
  'berok',
  'kotus',
  'cekik',
  'gatha',
  'layon',
  'rengu',
  'geweh',
  'layad',
  'gitel',
  'cling',
  'rumil',
  'anteg',
  'pilek',
  'camuk',
  'kolok',
  'nutug',
  'traju',
  'pitho',
  'giyat',
  'lipca',
  'tikus',
  'berci',
  'bliyo',
  'ketib',
  'inggu',
  'luweh',
  'cilum',
  'mlecu',
  'nusuh',
  'merki',
  'melur',
  'musna',
  'septa',
  'anila',
  'ngyai',
  'truna',
  'misuh',
  'kejot',
  'iyeka',
  'mojid',
  'kanca',
  'madas',
  'cakur',
  'bayur',
  'weruh',
  'baung',
  'neras',
  'sangi',
  'capah',
  'darah',
  'kawus',
  'sabab',
  'pinal',
  'mardi',
  'tapwa',
  'manut',
  'kotor',
  'nrang',
  'nawar',
  'cekek',
  'muges',
  'petri',
  'tatag',
  'karep',
  'mejao',
  'mudhi',
  'gumun',
  'baceg',
  'kagum',
  'kusik',
  'kayol',
  'ambek',
  'pakes',
  'tegel',
  'kawud',
  'doweh',
  'wereh',
  'ciyum',
  'bosok',
  'cimak',
  'bulan',
  'berah',
  'gogik',
  'nihan',
  'koled',
  'penek',
  'pelad',
  'kiwat',
  'resik',
  'kasah',
  'gelet',
  'geyol',
  'teguh',
  'wyati',
  'belit',
  'kulhu',
  'bucik',
  'bodin',
  'brung',
  'rojod',
  'gamoh',
  'tatal',
  'korud',
  'kalak',
  'priya',
  'mecok',
  'toros',
  'jeruk',
  'garah',
  'walih',
  'rineh',
  'grita',
  'sarad',
  'tipak',
  'terek',
  'kluku',
  'kloko',
  'brana',
  'ubung',
  'muyar',
  'nyapa',
  'jelma',
  'wawas',
  'siwer',
  'ngwor',
  'sepud',
  'raweh',
  'byung',
  'cocot',
  'serit',
  'matra',
  'lodra',
  'jabur',
  'keren',
  'mulud',
  'wirog',
  'mengo',
  'ambur',
  'tekad',
  'maoni',
  'nicil',
  'blere',
  'subuh',
  'nebek',
  'sabit',
  'jarit',
  'kejek',
  'dumoh',
  'wejah',
  'wajra',
  'santo',
  'moler',
  'mrana',
  'judha',
  'traya',
  'selok',
  'gigal',
  'tewah',
  'papal',
  'muaya',
  'nipas',
  'nebok',
  'ceceg',
  'trang',
  'notog',
  'drona',
  'cegeh',
  'loroh',
  'pesut',
  'tamat',
  'jotos',
  'pompa',
  'pemes',
  'niksa',
  'candu',
  'guwak',
  'wekca',
  'naker',
  'sepir',
  'bahni',
  'gobig',
  'gwaya',
  'ijrah',
  'keten',
  'gerot',
  'warga',
  'gayol',
  'uyang',
  'rurah',
  'kedug',
  'keras',
  'bowol',
  'lisir',
  'mider',
  'maseg',
  'tapel',
  'krosa',
  'leren',
  'muwah',
  'gatra',
  'gumoh',
  'undar',
  'ngisa',
  'sumet',
  'sukla',
  'doloh',
  'kewoh',
  'auman',
  'biyuh',
  'megar',
  'tipar',
  'celuk',
  'sigun',
  'papah',
  'pitak',
  'gence',
  'menur',
  'kicik',
  'buras',
  'nuwak',
  'tuter',
  'sebak',
  'losoh',
  'lakon',
  'edhot',
  'tabag',
  'caksu',
  'upaya',
  'semur',
  'nganu',
  'mrene',
  'pelek',
  'kilen',
  'mecak',
  'buwen',
  'gutuk',
  'impes',
  'lebek',
  'cahya',
  'yayan',
  'carek',
  'lapal',
  'medem',
  'gudir',
  'utara',
  'ngebo',
  'wejek',
  'leksa',
  'dlima',
  'gedug',
  'bukak',
  'kuwas',
  'leyeh',
  'komah',
  'tepus',
  'ngane',
  'gegeh',
  'iyung',
  'sedul',
  'tepak',
  'basan',
  'najan',
  'bobol',
  'waked',
  'wanuh',
  'kepoh',
  'rewot',
  'kukup',
  'dadek',
  'kapat',
  'ceret',
  'udhek',
  'momok',
  'gebug',
  'rarem',
  'lanat',
  'mewek',
  'kitha',
  'ulang',
  'obong',
  'micak',
  'pagir',
  'pocol',
  'mulet',
  'modho',
  'cenil',
  'mojah',
  'angge',
  'kubur',
  'solah',
  'menus',
  'bogel',
  'aneng',
  'nging',
  'ganco',
  'tuban',
  'juleg',
  'useng',
  'bobuk',
  'pahim',
  'dilat',
  'kamar',
  'juweh',
  'meneh',
  'wilis',
  'tulad',
  'landi',
  'keder',
  'kacek',
  'tegan',
  'tajap',
  'suwal',
  'gerah',
  'encos',
  'tebih',
  'sriti',
  'ladhu',
  'tabek',
  'paelu',
  'juluk',
  'satus',
  'wujuk',
  'sumed',
  'bejud',
  'pupuk',
  'ngeli',
  'micek',
  'sayut',
  'tleku',
  'sojar',
  'engen',
  'poluk',
  'rujak',
  'kirda',
  'rahab',
  'guyer',
  'bedad',
  'lecok',
  'tugel',
  'kowar',
  'ceker',
  'udhil',
  'kerik',
  'gober',
  'kukus',
  'sesuk',
  'wudhu',
  'begok',
  'tebal',
  'isnen',
  'tapih',
  'garok',
  'bosen',
  'ahlul',
  'gecos',
  'repen',
  'jikot',
  'thelo',
  'wigih',
  'parik',
  'geseh',
  'mlaka',
  'belas',
  'dodok',
  'mepeg',
  'becos',
  'incek',
  'jebul',
  'anjor',
  'mekas',
  'menit',
  'luwur',
  'lemer',
  'mewer',
  'jerak',
  'putek',
  'merit',
  'sotah',
  'cawan',
  'piyek',
  'comel',
  'manek',
  'cepot',
  'gadur',
  'tarum',
  'geger',
  'mendi',
  'mabul',
  'anjuk',
  'jeput',
  'misik',
  'peyor',
  'nyepa',
  'dasih',
  'ksema',
  'jawes',
  'madha',
  'letak',
  'picis',
  'mudha',
  'boyok',
  'umpet',
  'cowok',
  'gonor',
  'picek',
  'nulad',
  'culik',
  'mawuh',
  'githi',
  'orgel',
  'dhiri',
  'regoh',
  'mraos',
  'luthu',
  'sosoh',
  'putat',
  'dhega',
  'wawoh',
  'kopor',
  'galat',
  'poros',
  'lamus',
  'talab',
  'warda',
  'anteb',
  'makna',
  'oncet',
  'lacak',
  'munah',
  'watsa',
  'anyer',
  'rebel',
  'mrail',
  'talak',
  'sleko',
  'labas',
  'arina',
  'julig',
  'balas',
  'kokum',
  'dakwa',
  'tegep',
  'samin',
  'klapa',
  'remuk',
  'wiron',
  'lorok',
  'kobed',
  'pugag',
  'jajah',
  'cekep',
  'upaka',
  'satwa',
  'sitok',
  'udara',
  'paben',
  'iring',
  'ebrah',
  'ampel',
  'bacek',
  'layos',
  'mejet',
  'suwap',
  'megat',
  'yogya',
  'injek',
  'isthi',
  'nikep',
  'untul',
  'jowan',
  'muyen',
  'mijen',
  'cilik',
  'reyok',
  'nampa',
  'wareh',
  'lepas',
  'engeh',
  'rabut',
  'cuwek',
  'keguh',
  'pelih',
  'lirwa',
  'powak',
  'biksu',
  'pehak',
  'cucup',
  'gerus',
  'pasar',
  'gawar',
  'uling',
  'lesah',
  'bilur',
  'antih',
  'jeben',
  'babar',
  'cawis',
]
