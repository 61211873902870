export const VALIDGUESSES = [
  'aadmi',
  'antri',
  'andap',
  'andum',
  'aamir',
  'aanch',
  'aaram',
  'aatma',
  'abhay',
  'afsar',
  'aggar',
  'ahaan',
  'akira',
  'aksar',
  'alone',
  'anand',
  'anari',
  'angel',
  'antim',
  'anwar',
  'arjun',
  'arzoo',
  'asoka',
  'ateet',
  'awara',
  'axone',
  'azaad',
  'azhar',
  'baaja',
  'baazi',
  'babul',
  'badal',
  'badla',
  'banjo',
  'barfi',
  'basti',
  'bhoot',
  'bhram',
  'billu',
  'black',
  'blank',
  'bobby',
  'cargo',
  'click',
  'court',
  'crook',
  'daddy',
  'daman',
  'dansh',
  'david',
  'dehak',
  'dharm',
  'dhoom',
  'dhoop',
  'dhuan',
  'dhund',
  'drive',
  'drohi',
  'drona',
  'elaan',
  'fanaa',
  'fareb',
  'fever',
  'force',
  'fuddu',
  'fugly',
  'gadar',
  'gattu',
  'gayab',
  'ghost',
  'gippi',
  'gross',
  'guddi',
  'guddu ',
  'guide',
  'gulal',
  'gunda',
  'hamid',
  'happi',
  'hatya',
  'hello',
  'insan',
  'iqbal',
  'irada',
  'issaq',
  'james',
  'jamun',
  'janam',
  'jimmy',
  'johan',
  'joker',
  'jugni',
  'jugnu',
  'julie',
  'jumbo',
  'karam',
  'karma',
  'kasak',
  'kasam',
  'kites',
  'kohra',
  'koyla',
  'lajja',
  'login',
  'maatr',
  'mahal',
  'mahan',
  'manto',
  'maska',
  'masti',
  'mohra',
  'money',
  'naach',
  'nagin',
  'naina',
  'nasha',
  'nayak',
  'panga',
  'phhir',
  'phool',
  'pizza',
  'qahar',
  'qissa',
  'queen',
  'raazi',
  'radhe',
  'radio',
  'raees',
  'rahul',
  'rajjo',
  'rakhi',
  'rakht',
  'ready',
  'rocky',
  'roohi',
  'saaho',
  'saaya',
  'sadak',
  'safar',
  'saina',
  'sanak',
  'sanam',
  'sanju',
  'satta',
  'satya',
  'sazaa',
  'sehar',
  'seher',
  'shaan',
  'shabd',
  'sheen',
  'shiva',
  'shree',
  'sitam',
  'sniff',
  'spark',
  'speed',
  'squad',
  'ssukh',
  'stree',
  'style',
  'swami',
  'swarg',
  'tadap',
  'taish',
  'tevar',
  'titli',
  'train',
  'tulsi',
  'udaan',
  'ungli',
  'vaada',
  'vardi',
  'velle',
  'vidya',
  'vivah',
  'wafaa',
  'wazir',
  'yaara',
  'yatra',
  'yodha ',
  'zakhm',
  'zeher',
  'ziddi',
  'zinda',
  'zulmi',
]
